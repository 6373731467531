
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from './../../environments/environment'
const url = environment.serviceUrl



@Injectable({
  providedIn: 'root'
})
export class LoginService {
  redirectoLogin = "https://openuni.samvaadpro.com";
  constructor(private http: HttpClient) { }

  login(data){
    return this.http.post(url+'Account/Login',data);
    
  }
  veryStudent(data){
    return this.http.post(url+'Account/LoginwithHallTicket',data);
  }
  gettoken(){  
    return !! localStorage.getItem("UserId");  
    } 
    logout(){
      localStorage.clear();
      window.open(this.redirectoLogin, "_self")
    }
}

