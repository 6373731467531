import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CommonService } from '../services/common.service';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-maincontent',
  templateUrl: './maincontent.component.html',
  styleUrls: ['./maincontent.component.css']
})
export class MaincontentComponent implements OnInit {
  roleId: any = localStorage.getItem('RoleId');
  constructor(private DashboardService: DashboardService, private route: Router, private commonService: CommonService,private toast: ToastrService) {
   this.roleId =atob(this.roleId)
    if ( this.roleId == 3) {
      this.getCource();
    }
    this.getDashboard()

  }

  course: any = {
    isSessions: false,
    isClasses: true
  }
  data: any = {}
  ngOnInit(): void {

  }

  getCource() {
    this.commonService.activateSpinner()
    this.DashboardService.getCource().subscribe((data: any) => {
      this.course = { ...this.course, ...data }
      this.stopSpinner()
    }, (e) => {

      this.stopSpinner()
    })
  }
  toggleCource(key, collapse) {
    this.course.isSessions = false;
    this.course.isClasses = false;
    this.course[key] = !collapse;
  }
  navigate(data, url) {
    delete data.Name;
    if(url=='home/postassessment'){
      this.route.navigateByUrl(`${url}?Id=${data.id}&CourseScheduleId=${data.CourseScheduleId}`).then(res =>{
        window.location.reload();
      });
    }
    console.log('data..',data);
    
    this.route.navigate([url], { queryParams: data })
  }
  getDashboard() {
    this.commonService.activateSpinner()
    this.DashboardService.loadDashboard().subscribe((res: any) => {
      this.stopSpinner()
      this.data = res?res:{};

    }, (e) => {

      this.stopSpinner()
    })
  }

  stopSpinner() {
    this.commonService.deactivateSpinner()
  }
  joinSession(item){
    this.commonService.activateSpinner();
    let payload={
      "UserId":localStorage.getItem('UserId'), //RoleId,TenantCode,UserId,Username
      "TenantCode":localStorage.getItem('TenantCode'),
      "Username":localStorage.getItem('Username'),
       "APPOINTMENT_ID":item.URL,
      "RoleId":localStorage.getItem('RoleId'),
       "CourseScheduleId":1
    }
    this.commonService.postCall('AVService/Joinurl',payload).subscribe(res=>{
     this.commonService.deactivateSpinner();
      if(res.JoinUrl){
        window.open(res.JoinUrl,"_blank")
      }else{
        this.toast.warning("Host Not joined");
      }
    },err=>{
     this.commonService.deactivateSpinner();
    });
    
  }
}
