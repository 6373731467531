import { AfterViewInit, Component, HostListener, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PhotoSnapComponent } from '../photo-snap/photo-snap.component';
import { environment } from 'src/environments/environment';
import { AudioRecComponent } from '../audio-rec/audio-rec.component';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login.service';
import { ProConfigService } from 'src/app/services/pro-config.service';
import * as faceapi from 'face-api.js'


@Component({
  selector: 'app-startexam',
  templateUrl: './startexam.component.html',
  styleUrls: ['./startexam.component.css']
})
export class StartexamComponent implements OnInit, AfterViewInit {
  time: any;
  assessment: any = {}
  questions: Array<any> = [];
  AssessmentInformation: any = {};
  assementData: any = {};
  count: number = 0;
  isNext: boolean = false;
  isPrev: boolean = true;
  isSubmited: boolean = false;
  answers: Array<any> = [];
  result: any;
  @ViewChild(PhotoSnapComponent) child;
  interval: any;
  checkInterval:any
  img: any;
  checkUser:any
  dateTime = new Date();
  loadAPI: Promise<any>;
  isCamerStop: boolean = true;
  @ViewChild(AudioRecComponent) audioCmp;
  setTimeoutInterval: number;
  audUrl: any;
  config: any;
  topValue: number;
  mouseY: number;
  imagesResp: any = [];
  tabcnt=0;
  mobcnt=0;
  mulfacecnt=0;
  nofacecnt=0;
  camcnt=0;
  radioStatus: boolean;
  changeQuestion: any;
  srno: any;
  numbool: boolean = false;
  options: Array<any> = [];
  firstValue: any;
  mapElement: Array<any> = [];
  item: any;
  previousIndex: any;
  isFirst: boolean = true;
  firstIndex: any;
  val: any;
  clickNext: number = 0;

  constructor(private configService: ProConfigService, @Inject(LOCALE_ID) public locale: string, private logoutService: LoginService, private sanitizer: DomSanitizer, private CommonService: CommonService, private route: Router, private toastr: ToastrService) {
    this.assessment = CommonService.assessment;
    this.config = this.configService.getConfigData();
    if (!Object.keys(this.assessment).length) {
      route.navigate(['home/postassessment'])
    } else {
      this.getAssessment()
    }
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  ngOnInit() {
   
      this.imagesResp = JSON.parse(localStorage.getItem('FRImages'))
    Promise.all([
      faceapi.nets.faceRecognitionNet.loadFromUri('assets/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('assets/models'), ,
      faceapi.nets.ssdMobilenetv1.loadFromUri('assets/models'),
    ]).then((async () => this.start()));

    this.checkInterval = setInterval((res) => {
  
      this.child.triggerSnapshot();
      localStorage.setItem('checkphoto',this.child.webcamImage.imageAsDataUrl);
      this.checkUser =  localStorage.getItem('checkphoto');
      if(this.checkUser){
        this.start();
      }

    }, 5005);

    
  
    this.config = this.configService.getConfigData();
    this.interval = setInterval((res) => {
      
      this.child.triggerSnapshot();
      this.audUrl = this.audioCmp.audioBlobUrl;
      this.img = this.child.webcamImage.imageAsBase64;
      if (this.img) {
        const payload = {
          photo: this.img
        }
        this.CommonService.imageCapture(payload).subscribe(res => {
          if (res['status'] === environment.MESSAGES.OK) {
            const message = (res['message'])
            this.toastr.error(message);
            const payload = {
              user_id: localStorage.getItem('UserId'),
              tnt_code: localStorage.getItem('TenantCode'),
              courseshd_id: localStorage.getItem('CourseScheduleId'),
              sa_id: this.assessment.SA_ID,
              createdby: localStorage.getItem('UserId'),
              photo_image: localStorage.getItem('latest-img'),
              events: res['data'].events
            }
            this.CommonService.InsertFrData(payload).subscribe(data => {
            })
            // Mobile Phone Detected
            if (res['data'].mobile_status.length > 0 && this.config[3]['proctoringtype_id'] === 4 && this.config[3]['proctoring_status']===false) {
              this.toastr.error('Mobile Phone Detected');
              setTimeout(() => {
                this.logoutService.logout();
              }, 3000);
            }
            else if (res['data'].mobile_status.length > 0 && this.config[3]['proctoringtype_id'] === 4 && this.config[3]['proctoring_status']===true) {
            
                this.mobcnt += 1;
                const msg = 'No of attempts Left ' + ' ' +  (this.config[3]['reminder_count']-this.mobcnt) + ' ' + 'Mobile Phone Detected'
                if(this.config[3]['reminder_count']>=this.mobcnt){
                  this.toastr.error(msg);
                }
                if(this.mobcnt>this.config[3]['reminder_count']){
                  const msg = 'Mobile phone detected more than' + ' ' + this.config[3]['reminder_count']   + ' ' + 'times' + ' ' + ',So, you cannot continue the exam, please contact adminstrator'
                  this.toastr.error(msg);
                  setTimeout(() => {
                    this.logoutService.logout();
                  }, 3000);
                }
              
        
            }
            // End Mobile Phone Detected

            // Multiple Face Detected
            if (res['data'].face_status.length > 0 && (this.config[1]['proctoringtype_id'] === 2 && this.config[1]['proctoring_status']===false) || (this.config[2]['proctoringtype_id'] === 3 && this.config[2]['proctoring_status']===false)) {
              this.toastr.error('More than one face detected or no face detected');
              setTimeout(() => {
                this.logoutService.logout();
              }, 3000);
            }
             else if (res['data'].face_status.length > 0 && (this.config[1]['proctoringtype_id'] === 2 && this.config[1]['proctoring_status']===true) || (this.config[2]['proctoringtype_id'] === 3 && this.config[2]['proctoring_status']===true)) {
            
                this.mulfacecnt += 1;
                const msg = 'No of attempts Left ' + ' ' +  (this.config[1]['reminder_count']-this.mulfacecnt) + ' ' + 'More than one Person Detected or no Face Detected'
                if(this.config[1]['reminder_count']>=this.mulfacecnt){
                  this.toastr.error(msg);
                }
                if(this.mulfacecnt>this.config[1]['reminder_count']){
                  const msg = 'More than one Person Detected or No face Detected' + ' ' + this.config[1]['reminder_count'] + ' ' + 'times' + ' ' + ',So, you cannot continue the exam, please contact adminstrator'
                  this.toastr.error(msg);
                  setTimeout(() => {
                    this.logoutService.logout();
                  }, 3000);
                }
              
            }

            //   ENDMultiple Face Detected

            // // NO Face Detected
            //  if (res['data'].face_status.length > 0 && this.config[2]['proctoringtype_id'] === 3 && this.config[2]['proctoring_status']===false) {
            //   this.toastr.error('No face detected');
            //   setTimeout(() => {
            //     this.logoutService.logout();
            //   }, 3000);
            // }
            //   if (res['data'].face_status.length > 0 && this.config[2]['proctoringtype_id'] === 3 && this.config[2]['proctoring_status']===true) {
            
            //     this.nofacecnt += 1;
            //     const msg = 'No of attempts Left ' + ' ' +  (this.config[2]['reminder_count']-this.nofacecnt) + ' ' + 'No Face Detected'
            //     if(this.config[2]['reminder_count']>=this.nofacecnt){
            //       this.toastr.error(msg);
            //     }
            //     if(this.nofacecnt>this.config[2]['reminder_count']){
            //       const msg = 'No Face Detected' + ' ' + this.config[2]['reminder_count'] + ' ' + 'times' + ' ' + ',So, you cannot continue the exam, please contact adminstrator'
            //       this.toastr.error(msg);
            //       setTimeout(() => {
            //         this.logoutService.logout();
            //       }, 3000);
            //     }
              
        
              
        
        
            // }

            //   NO Face Detected

          }

        });
      }
    }, 30004);

  }
  async start() {
    const canvas = require("canvas");
    const labeledFaceDescriptors = await this.loadLabeledImages()
    const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors, 0.6)
    let image = await canvas.loadImage(this.checkUser);
    const displaySize = { width: image.width, height: image.height }
    faceapi.matchDimensions(canvas, displaySize)
    const detections = await faceapi.detectAllFaces(image).withFaceLandmarks().withFaceDescriptors()
    const resizedDetections = faceapi.resizeResults(detections, displaySize)
    const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor));
    if (results.length === 1 && results[0]['label'] === 'unknown') {
      this.toastr.error('Photo is not matched');
      setInterval(res=>{
        this.logoutService.logout();
       }, 2000);
    

    }
  // else if(results.length === 1 && results[0]['distance']>0.5 && results[0]['label'] === 'Photo Matched'){
  //     this.toastr.error('Photo is not matched');
  //     setInterval(res=>{
  //       this.logoutService.logout();
  //      }, 2000);
  //   }

  }

  loadLabeledImages() {
    this.imagesResp = JSON.parse(localStorage.getItem('FRImages'));
    if (this.imagesResp) {
      const labels = ['Photo Matched']
      return Promise.all(
        labels.map(async label => {
          const descriptions = []
          for (let i = 0; i < this.imagesResp.length; i++) {
            const element = this.imagesResp;
            if(element[i].Image.includes('data:image/jpeg;base64,')){
              const images = element[i].Image;
              if (element && images) {
                const img = await faceapi.fetchImage(images);
                const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
                descriptions.push(detections.descriptor)
              }
  
            }
            else{
              const cnt = 'data:image/jpeg;base64,'
              const images = cnt + element[i].Image;
              if (element && images) {
                const img = await faceapi.fetchImage(images);
                const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
                descriptions.push(detections.descriptor)
              }
  
            }
        
          }

          return new faceapi.LabeledFaceDescriptors(label, descriptions)
        })
      )
    }
  }

  audioTrigger() {
    this.audioCmp.startAudioRecording();

  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  someMethod(event) {
    if (event === 'true') {
      //this.submit();
      this.logoutService.logout();

    }
  }
  ngAfterViewInit(): void {
    this.audioCmp.startAudioRecording();



  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = ["assets/js/settings.js"];
      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }

  @HostListener('window:mouseout', ['$event'])
  onMouseUp(event) {
    this.mouseY = 0;
    this.topValue = 0;
    this.mouseY = event.clientY;
    if (this.config[0]['proctoringtype_id'] === 1 && this.config[0]['proctoring_status']===false) {
      if (this.mouseY < this.topValue) {
      
        const msg = 'Tried to open Tab,You cannot continue the exam, please contact adminstrator'
        this.toastr.error(msg);
        setTimeout(() => {
          this.logoutService.logout();
        }, 3000);

      }


    }
  
    else  if (this.config[0]['proctoringtype_id'] === 1 && this.config[0]['proctoring_status']===true) {
      if (this.mouseY < this.topValue) {
        this.tabcnt += 1;
        const msg = 'No of attempts Left ' + ' ' +  (this.config[0]['reminder_count']-this.tabcnt) + ' ' + 'Tried to open Tab.'
        if(this.config[0]['reminder_count']>=this.tabcnt){
          this.toastr.error(msg);
        }
        if(this.tabcnt>this.config[0]['reminder_count']){
          const msg = 'Tried to open Tab more than' + ' ' + this.config[0]['reminder_count']   + ' ' + ',So, you cannot continue the exam, please contact adminstrator'
          this.toastr.error(msg);
          setTimeout(() => {
            this.logoutService.logout();
          }, 3000);
        }
      

      }


    }
  }






  // getAssessment() {
  //   let data: any = {
  //     AssessmentCourseId: this.assessment.ASSESSMENT_COURSE_ID,
  //     AssessmentId: this.assessment.ASSESSMENT_ID,
  //     AssessmentType: 1,
  //     AssessmentUserId: localStorage.getItem('UserId'),
  //     CourseScheduleId: this.assessment.ASSESSMENT_COURSESECHD_ID,
  //     ScheduleId: this.assessment.SA_ID,
  //     TenantCode: localStorage.getItem('TenantCode'),
  //     NoOfQuestions: this.assessment.ASSESSMENT_NO_OF_QUESTIONS
  //   }
  //   this.activeSpinner();
  //   this.CommonService.takeExam(data).subscribe((res: any) => {
  //     this.deactivateSpinner();
  //     if (typeof res == 'string') {
  //       this.toastr.warning(res);
  //     } else {
  //       this.assementData = res;
  //       this.AssessmentInformation = res['AssessmentInformation'][0];
  //       this.dataTransfer()
  //     }
  //   }, (e) => {
  //     this.deactivateSpinner();
  //     if (e.error.text == "Your no.of attempts left is 0,please contact Administrator for further assistance") {
  //       this.toastr.error(e.error.text);
  //       this.route.navigate(['home/postassessment'])
  //     } else {
  //       this.toastr.error(e.error.text);
  //       this.route.navigate(['home/postassessment'])
  //     }
  //   })
  // }
  // dataTransfer() {

  //   let t = this.AssessmentInformation.ASSESSMENT_TIMINGFORASSESSMENT * 60;
  //   this.time = t;
  //   let data: Array<any> = [];
  //   let questions = this.assementData.AssessmentQuestions;
  //   let options: Array<any> = this.assementData.AssessmentOptions;
  //   questions.map((q) => {
  //     let qId = q.QUESTION_ID;
  //     let op = options.filter(option => option.QUESTION_ID == qId) || []
  //     q.options = op;
  //   })
  //   this.questions = questions;
  //   this.questions[0].isShow = true;

  // }

  // handleEvent(event) {
  //   if (event.action == "done") {
  //     this.toastr.info('Time Out');

  //     setTimeout(() => this.submit(), 200)
  //   }
  // }

  // next() {
  //   this.isPrev = false;
  //   this.questions[this.count].isShow = false;
  //   this.count++;
  //   this.questions[this.count].isShow = true;
  //   if (this.count == this.questions.length - 1) {
  //     this.isNext = true
  //   }
  // }
  // prev() {
  //   this.isNext = false
  //   this.questions[this.count].isShow = false;
  //   this.count--;
  //   this.questions[this.count].isShow = true;
  //   if (!this.count) {
  //     this.isPrev = true
  //   }
  // }

  // submit() {
  //   this.activeSpinner();
  //   let data: any = {
  //     AssessmentId: this.assessment.ASSESSMENT_ID,
  //     AssessmentType: 1,
  //     CourseId: this.assessment.ASSESSMENT_COURSE_ID,
  //     SaId: this.assessment.SA_ID,
  //     PassPercentage: this.assessment.ASSESSMENT_MINPERCENTAGE,
  //     AssessmentAnswers: []
  //   }
  //   let answers = [];
  //   this.questions.map((item) => {
  //     let obj: any = {};
  //     obj.TypeId = item.TYPEID;
  //     obj.QuestionAnswer = item.QUESTION_ANSWER
  //     obj.QuestionId = item.QUESTION_ID;
  //     obj.QuestionMarks = item.ACTUALMARKS;
  //     obj.Selected = item.ANSWER_GIVENANSWER || '';
  //     answers.push(obj)
  //   })
  //   data.AssessmentAnswers = answers;
  //   this.CommonService.setAssessments(data).subscribe((res: any) => {
  //     this.isSubmited = true;
  //     this.deactivateSpinner();
  //     if (Array.isArray(res)) {
  //       this.result = res[0];
  //       this.toastr.success('Thanks for using Samvaad Proctoring Solution');
  //       this.checkAnswers();
  //     } else {
  //       this.toastr.success(res.message);
  //       localStorage.removeItem('camerDisabled');
  //       this.toastr.success('Thanks for using Samvaad Proctoring Solution');
  //     }
  //   }, (err) => {
  //     this.deactivateSpinner();
  //     this.toastr.error(err.error)
  //   })
  // }

  // checkAnswers() {
  //   let a_id = this.assessment.ASSESSMENT_ID;
  //   let sa_id = this.assessment.SA_ID
  //   this.CommonService.checkAnswers(a_id, sa_id).subscribe((res: any) => {
  //     this.answers = res;
  //   })
  // }
  // confirm() {
  //   var r = confirm("Are you want Submit the Exam ? Click 'OK' to Submit Exam or 'Cancel' to Continue Exam");
  //   if (r == true) {
  //     this.submit();
  //     if (this.interval) {
  //       clearInterval(this.interval);
  //       this.isCamerStop = false;
  //     }
  //   } else {

  //   }
  // }

  back() {
    var r = confirm("Are you want Submit the Exam ? Click 'OK' to Submit Exam or 'Cancel' to Continue Exam");
    if (r == true) {
      this.submit();
      if (this.interval) {
        clearInterval(this.interval);
        this.isCamerStop = false;
        this.route.navigate(['home/postassessment']);
      }
    } else {

    }


  }
  previousBack() {
    this.route.navigate(['home/postassessment']);
  }



  // ----New Design Exam code------------------------------------------------------------------------------------------


  getAssessment() {
    let data: any = {
      AssessmentCourseId: this.assessment.ASSESSMENT_COURSE_ID,
      AssessmentId: this.assessment.ASSESSMENT_ID,
      AssessmentType: 1,
      AssessmentUserId: localStorage.getItem('UserId'),
      CourseScheduleId: this.assessment.ASSESSMENT_COURSESECHD_ID,
      ScheduleId: this.assessment.SA_ID,
      TenantCode: localStorage.getItem('TenantCode'),
      NoOfQuestions: this.assessment.ASSESSMENT_NO_OF_QUESTIONS
    }
    this.activeSpinner();
    this.CommonService.takeExam(data).subscribe((res: any) => {
      this.deactivateSpinner();
      if (typeof res == 'string') {
        this.toastr.warning(res);
      } else {
        this.assementData = res;
        this.AssessmentInformation = res['AssessmentInformation'][0];
        this.dataTransfer()
      }
    }, (e) => {
      this.deactivateSpinner();
      if (e.error.text == "Your no.of attempts left is 0,please contact Administrator for further assistance") {
        // alert(e.error);
        this.toastr.error(e.error.text);
        this.route.navigate(['HOME/postassessment'])
      } else {
        this.toastr.error(e.error.text);
        this.route.navigate(['HOME/postassessment'])
      }
    })
  }
  dataTransfer() {
    let t = this.AssessmentInformation.ASSESSMENT_TIMINGFORASSESSMENT * 60;
    this.time = t;
    let data: Array<any> = [];
    let questions = this.assementData.AssessmentQuestions;
    let options: Array<any> = this.assementData.AssessmentOptions;
    questions.map((q) => {
      let qId = q.QUESTION_ID;
      let op = options.filter(option => option.QUESTION_ID == qId) || []
      q.options = op;
    })
    this.questions = questions;
    this.mapElement = this.questions.map((e: any) => ({ ...e, isSelected: false, isReview: false, isAnswered: false }));
    // this.mapElement.map((e: any) => {
    //   e.options.map((res: any) => { res.selectedOption = false })
    // });
    this.mapElement[0].isSelected = true;
    this.previousIndex = 0;
    // this.srno = this.firstValue.SRNO;
    // this.changeQuestion = this.firstValue.QUESTION_QUESTION;
    this.questions[0].isShow = true;
    this.selectedQuestion(0);
  }

  handleEvent(event) {
    if (event.action == "done") {
      this.toastr.info('Time Out');

      setTimeout(() => this.submit(), 200)
    }
  }

  next() {
    // this.isPrev = false;
    // this.questions[this.count || this.firstValue].isShow = false;
    // this.count++;
    // this.firstValue++
    // this.questions[this.count || this.firstValue].isShow = true;
    // if (this.count == this.questions.length - 1) {
    //   this.isNext = true
    // }



  }
  prev() {
    // this.isNext = false
    // this.questions[this.count].isShow = false;
    // this.count--;
    // this.questions[this.count].isShow = true;
    // if (!this.count) {
    //   this.isPrev = true;
    // }
  }

  submit() {
    this.activeSpinner();
    let data: any = {
      AssessmentId: this.assessment.ASSESSMENT_ID,
      AssessmentType: 1,
      CourseId: this.assessment.ASSESSMENT_COURSE_ID,
      SaId: this.assessment.SA_ID,
      PassPercentage: this.assessment.ASSESSMENT_MINPERCENTAGE,
      AssessmentAnswers: []
    }
    let answers = [];
    this.questions.forEach((item) => {
      let obj: any = {};
      obj.TypeId = item.TYPEID;
      obj.QuestionAnswer = item.QUESTION_ANSWER
      obj.QuestionId = item.QUESTION_ID;
      obj.QuestionMarks = item.ACTUALMARKS;
      obj.Selected = item.options.find(e => !e.IS_OPTION_RANDOM == false)?.IS_OPTION_RANDOM || '';
      answers.push(obj)
    });
    data.AssessmentAnswers = answers;
    this.CommonService.setAssessments(data).subscribe((res: any) => {
      this.isSubmited = true;
      this.clickNext = 0;
      this.deactivateSpinner();
      if (Array.isArray(res)) {
        this.result = res[0];
        this.checkAnswers();
      } else {
        this.toastr.success(res.message);
      }
    }, (err) => {
      this.deactivateSpinner();
      this.toastr.error(err.error);
    })
  }


  selectedQuestion(index: any) {

    if (index == 'next') {
      this.isPrev = false;
      this.clickNext = this.clickNext + 1;
      this.firstIndex = this.mapElement[this.clickNext];
      this.changeQuestion = this.firstIndex.QUESTION_QUESTION;
      this.srno = this.firstIndex.SRNO;
      this.options = this.firstIndex.options;
      let indexValue = this.firstIndex.SRNO;
      let lastIndex = indexValue - 1;
      let previousQtn = this.mapElement.filter((e: any) => e.SRNO == lastIndex);
      if (!previousQtn[0].isReview) {
        previousQtn[0].isReview = true;
      }
      if (this.clickNext == this.questions.length - 1) {
        this.isNext = true;
      }

    } else if (index == 'prev') {
      this.isNext = false;
      this.clickNext = this.clickNext - 1;
      let item = this.mapElement[this.clickNext];
      this.changeQuestion = item.QUESTION_QUESTION;
      this.srno = item.SRNO;
      this.options = item.options;
      if (!this.clickNext) {
        this.isPrev = true;
      }
    } else if (index == 'options') {
      let sId = this.mapElement.filter((e: any) => e.SRNO == this.firstIndex.SRNO);
      if (!sId[0].isAnswered) {
        sId[0].isAnswered = true;
      }

      // this.mapElement.map((e: any) => {
      //   e.options.map((res: any) => { res.selectedOption = false })
      // });

      // this.val = sId[0].options.find((e: any) => e.QSTOPT_OPTION == event);
      // this.val = this.mapElement.filter((e:any)=>e.options.find((ele:any)=>ele.QSTOPT_OPTION == event));

      // this.val.selectedOption = true;
      // console.log(this.val);




      // console.log(sId, '====================', this.mapElement);




      // Update the completion status of Project 2 for employee John

      // let updatedAnswer = this.mapElement.map(e => {
      //   if (e.SRNO == this.firstIndex.SRNO) {
      //     let updatedOptions = e.options.map(op => {
      //       if (op.QSTOPT_OPTION == event) {
      //         return { ...op, selectedOption: true };
      //       } else {
      //         return op;
      //       }
      //     });
      //     return { ...e, options: updatedOptions };
      //   } else {
      //     return e;
      //   }
      // });
      // this.mapElement = updatedAnswer


      // if (!this.firstIndex.isAnswered) {
      //   this.firstIndex.isAnswered = true;
      // }
      // if (!this.item?.isAnswered && !this.item?.isAnswered) {
      //   this.item.isAnswered = true;
      // }
      // console.log(answer);

      // answer = true;
      // if (answer && event.target.checked) {
      //   document.getElementById('qtnBtn_'+index).style.backgroundColor = '#00FF00';
      // }

    } else {

      this.clickNext = index;
      // let item = this.mapElement[index];
      this.firstIndex = this.mapElement[index];
      this.firstValue = index;
      this.changeQuestion = this.firstIndex.QUESTION_QUESTION;
      this.srno = this.firstIndex.SRNO;
      this.options = this.firstIndex.options;
      this.isPrev = false;
      this.isNext = false;

      if (this.clickNext == this.questions.length - 1) {
        this.isNext = true
      }
      if (!this.clickNext) {
        this.isPrev = true;
      }
      let previousQtn = this.mapElement.filter((e: any, i: any) => i == this.previousIndex);
      if (this.isFirst && this.previousIndex == 0 && index == 0) {
        return this.isFirst = false;
      } else {
        if (!previousQtn[0].isReview) {
          previousQtn[0].isReview = true;
        }
        this.previousIndex = index;
      }
    }

    // let item: any = this.mapElement.find((item: any) => item.isSelected == true);
    // console.log(item);

    // let ads = this.questions.find((e: any) => e.SRNO == item.SRNO);
    // this.srno = ads.SRNO;
    // this.changeQuestion = ads.QUESTION_QUESTION;


    // this.numbool = true;

  }
  checkAnswers() {
    let a_id = this.assessment.ASSESSMENT_ID;
    let sa_id = this.assessment.SA_ID
    this.CommonService.checkAnswers(a_id, sa_id).subscribe((res: any) => {
      this.answers = res;
    })
  }
  confirm() {
    var r = confirm("Press 'OK' to End Exam and 'Cancel' to Continue Exam.");
    if (r == true) {
      this.submit()
    } else {

    }
  }


  // -----------------------------------------------------------------------------------------------------------------
}
