import { Component, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as faceapi from 'face-api.js'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import customProtocolCheck from "custom-protocol-check";


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;
  v_
  @ViewChild('canvas')
  public canvas: ElementRef
  interval
  videoInterval
  neutralDetections
  userInfo: any = {};
  takeExamQueryParams: any = {};
  imageUrl: any;
  hpyCnt: any = 0
  ntrCnt: any = 0
  imagesResp: any = [];
  cnt = 0;
  redirectoLogin = "https://openuni.samvaadpro.com";
  stopinterVal: boolean = false;
  constructor(@Inject(PLATFORM_ID) private _platform: Object, private toastr: ToastrService, private _Activatedroute: ActivatedRoute, private cdr: ChangeDetectorRef, private userService: LoginService, private CommonService: CommonService, private spinner: NgxSpinnerService, private route: Router) {
    this._Activatedroute.queryParamMap
    .subscribe((params) => {
      this.userInfo = {
        userId: params.get('userId'),
        TenantCode: params.get('TenantCode'),
        id: params.get('id'),
        CourseScheduleId: params.get('CourseScheduleId'),
      }
      this.takeExamQueryParams = {
        id: params.get('id'),
        CourseScheduleId: params.get('CourseScheduleId'),
      }

    }


    );
   
    this.getStudentImages();

  }
  ngOnInit() {
    // start();
    // loadLabeledImages();
    this._Activatedroute.queryParamMap
    .subscribe((params) => {
      this.userInfo = {
        userId: params.get('userId'),
        TenantCode: params.get('TenantCode'),
        id: params.get('id'),
        CourseScheduleId: params.get('CourseScheduleId'),
      }
      this.takeExamQueryParams = {
        id: params.get('id'),
        CourseScheduleId: params.get('CourseScheduleId'),
      }

    }


    );
    this.getStudentImages();
    this.ImageStart()
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('assets/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('assets/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('assets/models'),
      faceapi.nets.faceExpressionNet.loadFromUri('assets/models')
    ]).then((async () =>
      this.start())
      );
    //localStorage.clear();
  }


  async ImageStart() {
    Promise.all([
      faceapi.nets.faceRecognitionNet.loadFromUri('assets/models'),
      //faceapi.nets.faceLandmark68Net.loadFromUri('assets/models'), ,
      faceapi.nets.ssdMobilenetv1.loadFromUri('assets/models'),
    ]).then((async () => this.process()));
    // if (localStorage.getItem('TenantCode') || localStorage.getItem('Username') || localStorage.getItem('CourseScheduleId')) {
    //   localStorage.clear();
    // }
  }
  async getStudentImages() {
  //  const payload = {
  //    TENANT_CODE: this.userInfo.TenantCode,
  //     RollNumber: this.userInfo.userId,
  //      ATTENDANCE_COURSE_SCHEDULE_ID: this.userInfo.CourseScheduleId };
  //  // const payload = { TENANT_CODE: localStorage.getItem('TenantCode'), 
  //   RollNumber: localStorage.getItem('UserId'),
  //    ATTENDANCE_COURSE_SCHEDULE_ID: localStorage.getItem('CourseId') }

  const payload = { TENANT_CODE: this.userInfo.TenantCode, RollNumber: this.userInfo.userId, ATTENDANCE_COURSE_SCHEDULE_ID: this.userInfo.CourseScheduleId }
    await this.CommonService.verifyFace(payload).subscribe(res => {
      this.imagesResp = res.FRImages;
      if (this.imagesResp.length == 0) {
        window.open(this.redirectoLogin, "_self")
        this.toastr.error('Photo Not Found, Please Contact LMS Administrator');
        localStorage.clear();

      }
    })
  }
  async process() {
    if (this.imageUrl) {
      const canvas = require("canvas");
      const labeledFaceDescriptors = await this.loadLabeledImages()
      const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors)
      let image = await canvas.loadImage(this.imageUrl);
      const displaySize = { width: image.width, height: image.height }
      faceapi.matchDimensions(canvas, displaySize)
      const detections = await faceapi.detectAllFaces(image).withFaceLandmarks().withFaceDescriptors()
      const resizedDetections = faceapi.resizeResults(detections, displaySize)
      const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor));
      if (results.length > 1) {
        this.toastr.error('More than one person detected, Please try again');
        window.location.reload();
        this.deactivateSpinner()
        return false;
      }

      if (results.length === 1 && results[0]['distance'] < 0.5 && results[0]['label'] === 'Photo Matched') {
        this.deactivateSpinner();
        sessionStorage.setItem('duplicateSession', '1');
        this.CommonService.userId = this.userInfo.userId;
        localStorage.setItem('loginurl', this.route.url);
        localStorage.setItem('UserId', this.userInfo.userId);
        localStorage.setItem('TenantCode', this.userInfo.TenantCode)
        localStorage.setItem('id', this.userInfo.id)
        localStorage.setItem('CourseScheduleId', this.userInfo.CourseScheduleId);
        localStorage.setItem('isLoggedIn', 'true');
        this.toastr.success('User Photo Match, Login Sucessful');
        localStorage.setItem('FRImages', JSON.stringify(this.imagesResp))
        //this.onFaceverified();
       // this.route.navigateByUrl('home')
      }
      if (results.length === 1 && results[0]['distance'] > 0.5 && results[0]['label'] === 'Photo Matched') {
        this.toastr.error('Photo not matched,Login is invalid');
        setInterval(res=>{
          window.location.reload();
         },1000)
        this.deactivateSpinner()
        return false;
      }
      if (results.length === 1 && results[0]['label'] === 'unknown') {
        this.toastr.error('Photo not matched,Login is invalid');
       setInterval(res=>{
        window.location.reload();
       },1000)
        this.deactivateSpinner()
        return false;

      }
    }
  }
onFaceverified(){
    //window.location.href = `C:\Program Files\SafeExamBrowser\Application\SafeExamBrowser.exe`
    const samvaadProtocolString = `SafeExamBrowser://Application\SafeExamBrowser`;
    const anchroTag = document.getElementById(
      "btnSamvaadSetUp"
    ) as HTMLAnchorElement;
    anchroTag.href = samvaadProtocolString;
    anchroTag.click();
// 80 cc 




    // launchUri(samvaadProtocolString,
    //   () => {
    //     window.location.href = this.getHomeUrl();
    //     console.log('Have fun with my app');
    //   },
    //   () => { this.toastr.warning('Please download Samvaad APP'); },
    //   () => { alert('Hey, did my app launch? If not, please install it. kthxbye'); });
  
}

openDesktopApplication() {


 window.open("safeExamBrowser.exe");
//`seb://${location.href}`;
const samvaadProtocolString = `sebs:`;


  customProtocolCheck(samvaadProtocolString,
    () => {
      this.toastr.warning('Samvaad app not found on local machine.');
      //console.log("Custom protocol not found.");
    },
    () => {
      console.log("Custom protocol found and opened the file successfully.");
     // this.checkSession(time.toString());
    }, 10000
    // () => { setTimeout(() => window.location.href = this.getHomeUrl(), 500); console.log("Custom protocol found and opened the file successfully."); }, 10000
  );
}
  loadLabeledImages() {
    if (this.imagesResp && this.imagesResp.length > 0) {
      const labels = ['Photo Matched']
      return Promise.all(
        labels.map(async label => {
          const descriptions = []
          for (let i = 0; i < this.imagesResp.length; i++) {
            const element = this.imagesResp;
            if(element[i].Image.includes('data:image/jpeg;base64,')){
              const images = element[i].Image;
              if (element && images) {
                const img = await faceapi.fetchImage(images);
                const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
                descriptions.push(detections.descriptor)
              }
  
            }
            else{
              const cnt = 'data:image/jpeg;base64,'
              const images = cnt + element[i].Image;
              if (element && images) {
                const img = await faceapi.fetchImage(images);
                const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
                descriptions.push(detections.descriptor)
              }
  
            }
     

          }

          return new faceapi.LabeledFaceDescriptors(label, descriptions)


        })
      )
    }
    else if (this.imagesResp.length == 0) {
      this.toastr.error('Photos not found, Please Contact LMS Adminstrator');

      setInterval(res => {
        window.open(this.redirectoLogin, "_self")
        localStorage.clear();
      }, 1000)

      this.deactivateSpinner();
      return
    }
  }

  async start() {
    await this.load()
    if (isPlatformBrowser(this._platform) && 'mediaDevices' in navigator) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((ms: MediaStream) => {
        const _video = this.video.nativeElement;
        _video.srcObject = ms;
        _video.play();
      });
    }
  }
  async load() {
    var canvas_bg = document.createElement("canvas");
    canvas_bg.width = this.video.nativeElement.width;
    canvas_bg.height = this.video.nativeElement.height;
    document.getElementById("camera").append(canvas_bg);
    var canvas_face = document.createElement("canvas");
    canvas_face.width = this.video.nativeElement.width;
    canvas_face.height = this.video.nativeElement.height;
    const canvas = faceapi.createCanvas(this.video.nativeElement)
    document.getElementById("camera").append(canvas);
    const displaySize = { width: this.video.nativeElement.width, height: this.video.nativeElement.height }
    faceapi.matchDimensions(canvas, displaySize)
    this.videoInterval = setInterval(async () => {
      const detections = await faceapi.detectAllFaces(this.video.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
      const resizedDetections = faceapi.resizeResults(detections, displaySize)
      this.neutralDetections = faceapi.resizeResults(detections, displaySize);
     // var context =  canvas.getContext('2d')
      //context.scale(2, 2);
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
      //canvas.getContext('2d').scale(2,2);
      
     
      faceapi.draw.drawDetections(canvas, resizedDetections)
      faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
      let lneutralCnt;
      if (this.neutralDetections[0]?.expressions?.neutral > 0.2) {
        this.cnt = 0;
        if (this.ntrCnt < 20) {
          this.ntrCnt += 1;
          localStorage.setItem('neu', this.ntrCnt);
          lneutralCnt = parseInt(localStorage.getItem('neu'));
        }

      }

      if (this.ntrCnt < 20 && this.neutralDetections[0]?.expressions?.happy > 0.5 && this.cnt == 0) {
        this.toastr.error('Please Dont Smile, Untill System asks you to smile');
        this.cnt++;
        return;
      }
      if (lneutralCnt == 20) {
        this.toastr.success('Please smile to check your face Moments')
        return;
      }

      if (this.ntrCnt == 20) {
        if (this.neutralDetections[0]?.expressions?.happy > 0.5) {
          if (this.hpyCnt < 20) {
            this.hpyCnt += 1;
            localStorage.setItem('hp', this.hpyCnt)
          }
        }


      }
      if (this.ntrCnt > 15 && this.hpyCnt > 9) {
        if (this.hpyCnt == 10) {
          this.toastr.success('Please wait validation your photo and moments')
          this.Capture();
        }

        return;
      }


    }, 100)



  }

  public Capture() {
    const video: any = document.getElementById("video");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d')
      .drawImage(video, 0, 0, canvas.width, canvas.height);
    this.imageUrl = canvas.toDataURL('image/jpeg');
    localStorage.setItem('imageurl',this.imageUrl)
    if (this.imageUrl) {
      this.byPassLogin();
    }
    clearInterval(this.videoInterval);

  }
  onStop() {
    this.video.nativeElement.pause();
    (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
    this.video.nativeElement.srcObject = null;
  }

  ngOnDestroy() {
    (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  byPassLogin() {
    this.activeSpinner();
    if ([null, "", undefined, NaN].includes(this.userInfo['TenantCode'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['userId'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['CourseScheduleId'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['id'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();;
      return false;
    }

    if (this.imageUrl) {
      this.process();
    }

  }
}
