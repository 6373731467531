import { DOCUMENT } from '@angular/common';
import { Component, ElementRef,HostListener,Inject,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent } from 'rxjs';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Proctoring';
  subscription;
  mouseover:boolean;
  Allow_New_Tab: boolean;
  Allow_Multiple_face_deceted: boolean;
  Allow_through_face_not_detected: boolean;
  Allow_Mobile_detected: boolean;
  Camera_Disabled: boolean;

  constructor(private element: ElementRef,@Inject(DOCUMENT) private document: any,private route:Router,private spinner: NgxSpinnerService,private commonService:CommonService){
    commonService.appComponent=this
  }

  elem;
   displayMediaOptions = {
    video: {
      cursor: "always"
    },
    audio: false
  };
  // onUpdateServer() {
  //   this.changesSaved = true;
  // }
  // canDeactivate() {
  //    if(this.changesSaved) {
  //      return true;
  //    } else {
  //      return confirm('Do you want to discard changes');
  //    }
  // }


  // @HostListener('mouseout')
  // onMouseOut() {
  // //  alert('Message')
  // }
  // $(document).ready(function(){
      
  //       });

  // @HostListener('window:mouseout', ['$event'])
  // onMouseUp(event) {
  //   var mouseY = 0;
  //   var topValue = 0;
  //   mouseY = event.clientY;
  //   console.log('mouseY..',mouseY)
  //   if(mouseY<topValue) {
      
  //                   //localStorage.setItem('mouseEvent',topValue+= 1);
  //                     alert("If you Open New Tab Your Exam Session will Expire");
  //                 }
                  
  //             }
              
            
  
  ngOnInit() {
    this.elem = document.documentElement;
    history.pushState(null, null, location.href);
    this.subscription = fromEvent(window, 'popstate').subscribe(_ => {
       history.pushState(null, null, location.href);
       var r = confirm("Your Session Will Expire ? Click 'OK' to Exit Exam or 'Cancel' to Continue Exam");
       if (r == true) {
        this.route.navigate(['pnf']);
        
        
       } else {
        return false;
        window.location.reload;
        

       }
     
    });
 
  


  
  
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
 }

  


  activateSpinner(){
    this.spinner.show();
  }
  deactivateSpinner(){
    this.spinner.hide();
  }
  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
// async capture()  {
//     const canvas = document.createElement("canvas");
//     const context = canvas.getContext("2d");
//     const video = document.createElement("video");
  
//     try {
//       const mediaDevices = navigator.mediaDevices as any;
//        const captureStream = await mediaDevices.getDisplayMedia();
//        console.log('capture stream.. ',captureStream);
//       video.srcObject = captureStream;
//       context.drawImage(video, 0, 0,);
//       const frame = canvas.toDataURL("image/png");
//       console.log('frame...',frame);
//       captureStream.getTracks().forEach(track => track.stop());
//       window.location.href = frame;
//     } catch (err) {
//       console.error("Error: " + err);
//     }
//   };
  
//  capture();
  // const button = document.getElementById('cake').onclick = () => capture() 



//    async capture(){
//      const canvas = document.createElement("canvas");
//      const context = canvas.getContext("2d");
//      const video = document.createElement("video");
//      var constraints ={video: {mediaSource: "screen", width: 320, height: 200}};
//      navigator.mediaDevices.getUserMedia(constraints)
// .then(function(mediaStream) {
//   video.srcObject = mediaStream;
//      context.drawImage(video, 0, 0);
//      const frame = canvas.toDataURL("image/png");
//      window.location.href = frame;
//      console.log('frame...', window.location.href);
//   video.onloadedmetadata = function(e) {
//     video.play();
//   };
// }).catch(function(err) { console.log(err.name + ": " + err.message); }); 



// // const screenshotTarget = document.body;

// // html2canvas(screenshotTarget).then((canvas) => {
// //     const base64image = canvas.toDataURL("image/png");
// //     window.location.href = base64image;
// //     console.log('frame...', window.location.href);
// // });
// }


}
