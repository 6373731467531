<div *ngIf='false' class="loginbg" style="background-image: url('./assets/img/bg-image.png');">
    <div class="container">
        <div class="row">
            <div class="offset-xl-4 col-xl-4">
                <div class="card  loginform">

                    <div class="card-body p-1 loginform">
                        <div id="login-row" class="row justify-content-center align-items-center">
                            <div id="login-column" class="col-xl-12">
                                <div id="login-box" class="col-xl-12">
                                    <form id="login-form" class="form" #form="ngForm" (ngSubmit)="registerUser(form)">
                                        <h3 class="text-center text-info">Verify Student</h3>
                                        <div class="form-group">
                                            <label for="username" class="text-info">Hallticket NO:</label><br>
                                            <!-- <input type="text" name="username" id="username" class="form-control"> -->
                                            <input type="rollnumber" class="form-control" id="rollnumber" placeholder="Roll Number"
                                                name="rollnumber" rollnumber required ngModel
                                                #rollnumber="ngModel">
                                            <span class="help-bpx" *ngIf="rollnumber.touched && !rollnumber.valid ">Please
                                                enter the rollnumber </span>
                                        </div>
                                      
                                        <div class="text-center">
                                            <div class="form-group">
                                                <!-- <label for="remember-me" class="text-info"><span>Remember me</span> <span><input id="remember-me" name="remember-me" type="checkbox"></span></label><br> -->
                                                <!-- <input type="submit" name="submit" class="btn btn-info btn-md" value="submit"> -->
                                                <button type="submit" class="btn btn-info btn-sm btn-block"
                                                    id="register" [disabled]="!form.valid">Submit</button>
                                            </div>
                                        </div>

                                        <!-- <div id="register-link" class="text-right">
                                        <a href="#" class="text-info">Register here</a>
                                    </div> -->
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




</div>

<div class="limiter">
    <div class="container-login100" style="background-image: url('../../../assets/images/dhanush/bg-image.png');">
        <div class="wrap-login100">
            <div class="text-center"> <img src="../../../assets/images/dhanush/ouk-logo-new.png" class="img-fluid" style="width: 60%;height: auto;" /></div>
            <br>
            <form class="login100-form validate-form">
                <span class="login100-form-title p-b-24"> <span style="font-size:22px;">Proctoring Solution</span> <br>
                    <!-- <span style="font-size:15px;">Learning Management System</span>  -->
                </span>
                <div class="wrap-input100 validate-input mt-4" data-validate="Valid Rollnumber">
                    <input class="input100" [(ngModel)]="data.rollnumber" type="text" name="rollnumber">
                    <span class="focus-input100" data-placeholder="Roll number"></span>
                </div>
                
                <div class="container-login100-form-btn">
                    <div class="wrap-login100-form-btn">
                        <div class="login100-form-bgbtn"></div>
                        <button class="login100-form-btn"> Login </button>
                    </div>
                </div>
                <!-- <div class="text-center p-t-23"> <span class="txt1"> Don’t have an account? </span> <a class="txt2" href="#"> Sign Up </a> </div> -->
            </form>
        </div>
    </div>
</div>