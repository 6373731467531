import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AudioRecordingService } from 'src/app/services/audio-recording.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-audio-rec',
  templateUrl: './audio-rec.component.html',
  styleUrls: ['./audio-rec.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AudioRecComponent implements OnInit, OnDestroy {
  isPlaying = false;
  displayControls = true;
  isAudioRecording = false;
  audioRecordedTime;
  audioBlobUrl;
  audioBlob;
  audioName;
  audioStream;
  aurl: string;
  audioConf = { audio: true };
  constructor(private ref: ChangeDetectorRef,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer, private CommonService: CommonService) {
    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isAudioRecording = false;
      this.ref.detectChanges();
    });
    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.audioRecordedTime = time;
      if (this.audioRecordedTime >= '01:00') {
        this.stopAudioRecording();
        //this.downloadAudioRecordedData();
      }
      if (this.audioRecordedTime == '01:00') {
        setTimeout(() => {
          this.clearAudioRecordedData();
        }, 5000)
      }
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      console.log('data..', data)
      this.audioBlob = data.blob;
      console.log('blob')
      this.audioName = data.title;
      this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(data.blob)
      );
      if (this.audioBlobUrl) {


        // let reader = new FileReader();
        // reader.readAsDataURL(this.audioBlob);
        // reader.onloadend = function () {
        //   var base64data = reader.result;
        //   console.log('audio url', base64data);
        //   // const base64data: string = reader.result as string;
        //   // localStorage.setItem('audioUrl',base64data)

        // }
        const payLoad = {
          audio: this.audioBlobUrl,
        }
        this.CommonService.audioCapture(payLoad);
        setTimeout(() => {
          this.startAudioRecording();
        }, 5000)
      }
      this.ref.detectChanges();
    });
  }


  ngOnInit(): void {
  }
  startAudioRecording() {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {
    if (this.isAudioRecording) {
      this.audioRecordingService.stopRecording();
      this.isAudioRecording = false;
    }
  }

  clearAudioRecordedData() {
    this.audioBlobUrl = null;
  }

  downloadAudioRecordedData() {
    this._downloadFile(this.audioBlob, 'audio/mp3', this.audioName);
  }

  ngOnDestroy(): void {
    this.abortAudioRecording();
  }

  _downloadFile(data: any, type: string, filename: string): any {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    //this.video.srcObject = stream;
    //const url = data;
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}

