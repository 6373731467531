import { Component, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as faceapi from 'src/assets/fc/face-api.min.js'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
declare var $: any

@Component({
  selector: 'app-login-python',
  templateUrl: './login-python.component.html',
  styleUrls: ['./login-python.component.css']
})
export class LoginPythonComponent implements OnInit {

  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;
  v_
  @ViewChild('canvas')
  public canvas: ElementRef
  interval
  videoInterval
  neutralDetections
  userInfo: any = {};
  takeExamQueryParams: any = {};
  imageUrl: any;
  hpyCnt: any = 0
  ntrCnt: any = 0
  imagesResp: any = [];
  cnt = 0;
  redirectoLogin = "https://openuni.samvaadpro.com";
  stopinterVal: boolean = false;
  enableButton: boolean;
  data: any = {}
  rollno: any;
  isVideoFlag = true;
  verifyPayload: {};
  constructor(@Inject(PLATFORM_ID) private _platform: Object, private toastr: ToastrService, private _Activatedroute: ActivatedRoute, private cdr: ChangeDetectorRef, private userService: LoginService, private CommonService: CommonService, private spinner: NgxSpinnerService, private route: Router) {

  }

  async ngOnInit() {
    Promise.all([
      faceapi.nets.faceRecognitionNet.loadFromUri('/assets/fc/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/assets/fc/models'),
      faceapi.nets.ssdMobilenetv1.loadFromUri('/assets/models'),
      faceapi.nets.tinyFaceDetector.loadFromUri('assets/models'),
      faceapi.nets.faceExpressionNet.loadFromUri('assets/models')
    ])
    if (isPlatformBrowser(this._platform) && 'mediaDevices' in navigator) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((ms: MediaStream) => {
        const _video = this.video.nativeElement;
        _video.srcObject = ms;
        _video.play()
          .then(res => {
            var canvas_bg = document.createElement("canvas");
            canvas_bg.width = this.video.nativeElement.width;
            canvas_bg.height = this.video.nativeElement.height;
            document.getElementById("camera").append(canvas_bg);
            var canvas_face = document.createElement("canvas");
            canvas_face.width = this.video.nativeElement.width;
            canvas_face.height = this.video.nativeElement.height;
            const canvas = faceapi.createCanvas(this.video.nativeElement)
            document.getElementById("camera").append(canvas);
            const displaySize = { width: this.video.nativeElement.width, height: this.video.nativeElement.height }
            faceapi.matchDimensions(canvas, displaySize)
            setInterval(async () => {
              const detections = await faceapi.detectAllFaces(this.video.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
              const resizedDetections = faceapi.resizeResults(detections, displaySize)
              this.neutralDetections = faceapi.resizeResults(detections, displaySize);
              canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
              faceapi.draw.drawDetections(canvas, resizedDetections)
              faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
              let lneutralCnt;
              if (this.isVideoFlag == false) {
                if (detections.length > 1) {
                  this.toastr.error('More than one Face Detected...');
                  window.location.reload();
                }
                if (this.neutralDetections[0]?.expressions?.neutral > 0.2) {
                  this.cnt = 0;
                  if (this.ntrCnt < 20) {
                    this.ntrCnt += 1;
                    localStorage.setItem('neu', this.ntrCnt);
                    lneutralCnt = parseInt(localStorage.getItem('neu'));
                  }

                }

                if (this.ntrCnt < 20 && this.neutralDetections[0]?.expressions?.happy > 0.5 && this.cnt == 0) {
                  this.toastr.error('Please Dont Smile, Untill System asks you to smile');
                  this.cnt++;
                  return;
                }
                if (lneutralCnt == 20) {
                  this.toastr.success('Please smile to check your face Moments')
                  return;
                }

                if (this.ntrCnt == 20) {
                  if (this.neutralDetections[0]?.expressions?.happy > 0.5) {
                    if (this.hpyCnt < 20) {
                      this.hpyCnt += 1;
                      localStorage.setItem('hp', this.hpyCnt)
                    }
                  }


                }
                if (this.ntrCnt > 15 && this.hpyCnt > 9) {
                  if (this.hpyCnt == 10) {
                    this.toastr.success('Please wait validation your photo and moments')
                    this.Capture();

                  }

                  return;
                }
              }

            }, 10)
          });
      })

    }

  }



  async getStudentImages() {
    const payload = {
      TENANT_CODE: localStorage.getItem('TenantCode'),
      RollNumber: localStorage.getItem('UserId'),
      ATTENDANCE_COURSE_SCHEDULE_ID: localStorage.getItem('CourseId')
    }
    await this.CommonService.verifyFace(payload).subscribe(res => {
      this.imagesResp = res.FRImages;
      if (this.imagesResp.length == 0) {
        window.open(this.redirectoLogin, "_self")
        this.toastr.error('Photo Not Found, Please Contact LMS Administrator');
        localStorage.clear();

      }
    })
  }


  public Capture() {
    const video: any = document.getElementById("video");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d')
      .drawImage(video, 0, 0, canvas.width, canvas.height);
    this.imageUrl = canvas.toDataURL('image/jpeg');
    this.verifyPayload = {
      loginImage: this.imageUrl,
      FRImages: this.imagesResp
    }


  }
  onStop() {
    this.video.nativeElement.pause();
    (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
    this.video.nativeElement.srcObject = null;
  }

  ngOnDestroy() {
    (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  registerUser() {

    let user = {
      rollnumber: this.rollno
    }
    this.userService.veryStudent(user).subscribe((succ) => {

      let userData: any = succ ?? {};
      if (userData) {
        this.isVideoFlag = false;
        this.getStudentImages()
        this.CommonService.userId = userData.USERID
        localStorage.setItem('UserId', userData.USERID);
        localStorage.setItem('Username', userData.FIRSTNAME);
        localStorage.setItem('TenantCode', userData.TENANTCODE)
        localStorage.setItem('RoleId', userData.Encrypt_Role)
        localStorage.setItem('DICTIONARYCODE', userData.DICTIONARYCODE)
        localStorage.setItem('CourseId', userData.CourseId);
        this.toastr.success('Please wait verify student Roll Number');
        // if(userData.USERID){
        //   this.dashboardService.getCource().subscribe((data: any) => {
        //     console.log('dashboardService...',data)
        //     // this.course = { ...this.course, ...data }
        //     // this.stopSpinner()
        //   }, (e) => {

        //     //this.stopSpinner()
        //   })
        // }
        // this.route.navigate(['/login'])


      }

    }, (err) => {
      this.isVideoFlag = false
      let e = err.error;

      this.toastr.error(e.text || 'Please try again later')
    })
  }




}
