<div class="container-scroller">
    <app-header></app-header>
    <div class="container-fluid page-body-wrapper" style="position:absolute">
        <app-sidebar></app-sidebar>
        <div class="main-panel">
            <!-- <app-maincontent></app-maincontent> -->

            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </div>

    </div>
</div>