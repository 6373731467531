<nav class="sidebar slide" id="sidebar">
    <ul class="nav" style="margin-top: 5rem;">

        <!-- <li class="nav-item">
            <a class="nav-link" (click)="previous()">
                <span class="menu-title"><i class="fa fa-home" aria-hidden="true"></i> Home</span>

            </a>
        </li> -->
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['dashboard']">
              <span class="menu-title">Dashboard</span>
              <i class="mdi mdi-view-dashboard menu-icon"></i>
            </a>
          </li>
      


        <li class="nav-item">
            <a class="nav-link" (click)="help()">
                <span class="menu-title"><i class="fa fa-question" aria-hidden="true"></i> Help</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="logout()">
                <span class="menu-title"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout </span>
            </a>
        </li>
    </ul>
</nav>


<span data-toggle="modal" data-target="#myModalhelp" id='help'></span>
<div class="modal fade" id="myModalhelp" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title" style="color: #006e9e !important;"> Help</h5>

                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <div><b>1. How to Test Webcam ?</b></div>

                <div> Click this Url <a href="https://webcamtests.com/" target="_blank"> https://webcamtests.com </a> to test Webcam</div>
                <hr>
                <div><b>2. How to Enable Webcam ?</b></div>
                <div>Click this Url <a href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop" target="_blank"> https://support.google.com/chrome/answer</a> to Enable Webcam</div>
                <hr>
                <div><b>3. How to reach us for any techincal support ?</b></div>
                <div>Phone:1800 891 2285 &nbsp;&nbsp;
                    <div>Email: <a href="mailto:info@samvaadpro.com">info@samvaadpro.com </a>
                    </div>
                    <div>Website <a href="https://scheduling.samvaad.pro/contactus" target="_blank">www.samvaad.pro</a></div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal" id="md_close">Close</button>
            </div>
        </div>
    </div>
</div>