import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  roleId: number | string = localStorage.getItem('RoleId') || 3;
  redirectoLogin = "https://openuni.samvaadpro.com";
  menus: Array<any> = []
  constructor(private route: ActivatedRoute,private r: Router, private router: Router, private service: CommonService) {
  
  }

  ngOnInit(): void {
    // this.menus = this.route.snapshot.data.menu;
    // console.log(this.menus)
    //   this.redirectoLogin=localStorage.getItem('loginurl')
    // console.log('login url',this.redirectoLogin);
  }
  getClass(name) {
    return "mdi " + name + " menu-icon"
  }

  navigate(path, menu) {
    if (!menu.childs.length && path) {
      this.router.navigate(['home/' + path])
    }

  }
  // previous() {
  //   //window.history.go(-1)
  //   this.router.navigateByUrl('home/postassessment')
  // }
  logout() {
    var r = confirm("Press 'OK' to Leave the Exam and 'Cancel' to Continue Exam.");
    if (r == true) {
    
      localStorage.clear();
      window.open(this.redirectoLogin, "_self")


    } else {

    }


  }

  close(){
    
  }
help(){
  document.getElementById('help').click();
}
}
