import { Component, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as faceapi from 'face-api.js'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import { clearInterval } from 'timers';
@Component({
  selector: 'app-expression-login',
  templateUrl: './expression-login.component.html',
  styleUrls: ['./expression-login.component.css']
})
export class ExpressionLoginComponent implements OnInit {
  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;
  v_
  @ViewChild('canvas')
  public canvas: ElementRef;
  WIDTH = 640;
  HEIGHT = 480;
  interval
  neutralDetections
  captures: string[] = [];
  mBlinkSound = new Audio("/sound/shotgun-firing1.mp3");
  public seconds: number;
  isAllowtoExicute: boolean = true;
  data: any = {};
  userInfo: any = {};
  takeExamQueryParams: any = {};
  imageUrl: any;
  imagesResp: any = [];
  constructor(@Inject(PLATFORM_ID) private _platform: Object, private toastr: ToastrService, private _Activatedroute: ActivatedRoute, private cdr: ChangeDetectorRef, private userService: LoginService, private CommonService: CommonService, private spinner: NgxSpinnerService, private route: Router) {
    this._Activatedroute.queryParamMap
      .subscribe((params) => {
        this.userInfo = {
          userId: params.get('userId'),
          TenantCode: params.get('TenantCode'),
          id: params.get('id'),
          CourseScheduleId: params.get('CourseScheduleId'),
        }
        this.takeExamQueryParams = {
          id: params.get('id'),
          CourseScheduleId: params.get('CourseScheduleId'),
        }

      }


      );
  }
  ngOnInit() {
    this.ImageStart()
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('assets/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('assets/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('assets/models'),
      faceapi.nets.faceExpressionNet.loadFromUri('assets/models')
    ]).then((async () =>
      this.start()));
      localStorage.clear();
  }


  async ImageStart() {
    this.getStudentImages();

    Promise.all([
      faceapi.nets.faceRecognitionNet.loadFromUri('assets/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('assets/models'), ,
      faceapi.nets.ssdMobilenetv1.loadFromUri('assets/models'),
    ]).then((async () => this.process()));
    if (localStorage.getItem('TenantCode') || localStorage.getItem('Username') || localStorage.getItem('CourseScheduleId')) {
      localStorage.clear();
    }
  }
  async getStudentImages() {
    const payload = { TENANT_CODE: this.userInfo.TenantCode, RollNumber: this.userInfo.userId, ATTENDANCE_COURSE_SCHEDULE_ID: this.userInfo.CourseScheduleId }
    await this.CommonService.verifyFace(payload).subscribe(res => {
      this.imagesResp = res[0];
    })
  }
  getTop(l) {
    return l
      .map((a) => a.y)
      .reduce((a, b) => Math.min(a, b));
  }

  getMeanPosition(l) {
    return l
      .map((a) => [a.x, a.y])
      .reduce((a, b) => [a[0] + b[0], a[1] + b[1]])
      .map((a: number) => a / l.length);
  }

  async process() {
    if (this.imageUrl) {
      const canvas = require("canvas");
      const labeledFaceDescriptors = await this.loadLabeledImages()
      const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors, 0.6)
      let image = await canvas.loadImage(this.imageUrl);
      const displaySize = { width: image.width, height: image.height }
      faceapi.matchDimensions(canvas, displaySize)
      const detections = await faceapi.detectAllFaces(image).withFaceLandmarks().withFaceDescriptors()
      const resizedDetections = faceapi.resizeResults(detections, displaySize)
      const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor));
      if (results.length > 1) {
        this.toastr.error('More than one person detected, Please try again');
        this.deactivateSpinner()
        return false;
      }

      if (results.length === 1 && results[0]['distance'] < 0.5 && results[0]['label'] === 'Photo Matched') {
        this.deactivateSpinner();
        sessionStorage.setItem('duplicateSession', '1');
        this.CommonService.userId = this.userInfo.userId;
        localStorage.setItem('loginurl', this.route.url);
        localStorage.setItem('UserId', this.userInfo.userId);
        localStorage.setItem('TenantCode', this.userInfo.TenantCode)
        localStorage.setItem('id', this.userInfo.id)
        localStorage.setItem('CourseScheduleId', this.userInfo.CourseScheduleId);
        localStorage.setItem('isLoggedIn', 'true');
        this.toastr.success('User Photo Match, Login Sucessful');
        localStorage.setItem('FRImages', JSON.stringify(this.imagesResp))
        this.route.navigateByUrl('home/postassessment')
      }
      if (results.length === 1 && results[0]['distance'] > 0.5 && results[0]['label'] === 'Photo Matched') {
        this.toastr.error('Photo not matched,Login is invalid');

        this.deactivateSpinner()
        return false;
      }
      if (results.length === 1 && results[0]['label'] === 'unknown') {
        this.toastr.error('Photo not matched,Login is invalid');

        this.deactivateSpinner()
        //this.retake();
        return false;

      }
    }
  }

  loadLabeledImages() {
    this.imagesResp = this.imagesResp.FRImages;
    if (this.imagesResp) {
      const labels = ['Photo Matched']
      return Promise.all(
        labels.map(async label => {
          const descriptions = []
          for (let i = 0; i < this.imagesResp.length; i++) {
            const element = this.imagesResp;
            const cnt = 'data:image/jpeg;base64,'
            const images = cnt + element[i].Image;
            if (element && images) {
              const img = await faceapi.fetchImage(images);
              const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
              descriptions.push(detections.descriptor)
            }


          }

          return new faceapi.LabeledFaceDescriptors(label, descriptions)


        })
      )
    }
    else {
      this.toastr.error('Photos not found, Please Contact LMS Adminstrator');
      this.deactivateSpinner();
      window.location.reload();
      return
    }
  }

  async start() {
    await this.load()
    if (isPlatformBrowser(this._platform) && 'mediaDevices' in navigator) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((ms: MediaStream) => {
        const _video = this.video.nativeElement;
        _video.srcObject = ms;
        _video.play();
      });
    }
  }
  async load() {

    //     const canvas = faceapi.createCanvas(this.video.nativeElement);
    //     document.getElementById("camera").append(canvas);
    //     const width = this.video.nativeElement.width;
    //     const height = this.video.nativeElement.width;
    //     const displaySize = { width: width, height: height }
    //     faceapi.matchDimensions(canvas, displaySize)
    //     this.interval =    setInterval(async () => {
    //       const detections = await faceapi.detectSingleFace(this.video.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
    //       this.resizedDetections = faceapi.resizeResults(detections, displaySize);
    //       // if(this.resizedDetections.expressions.neutral> 0.5 && this.resizedDetections.expressions.neutral> 0.7){
    //       if(this.resizedDetections.detection.score > 0.8){
    //       localStorage.setItem('neutral',this.resizedDetections.detection.score)
    //       // const v:=  localStorage.setItem('neutral',1)
    //       // if(v==='1'){
    //         this.toastr.success('Please Look UP');

    //         // setInterval(res=>{
    //         //     localStorage.removeItem('neutral')
    //         // },2000)
    //       // }

    //         // clearInterval(this.interval);
    //         // setInterval(async () => {

    //         //   setInterval(this.interval)
    //         //  }, 1003)


    //         if(this.resizedDetections.expressions.happy){
    //           // const v:any =  localStorage.setItem('happy','1')
    //           // if(v==='1'){
    //           //   this.toastr.success('Please Look down');

    //           //   setInterval(res=>{
    //           //       localStorage.removeItem('happy')
    //           //   },2000)
    //           // }
    //         }
    //       }

    //       if (this.resizedDetections && this.resizedDetections.detection.score > 0.7 && this.resizedDetections.expressions.happy > 0.5) {

    // //this.Capture();
    //       }

    //       canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
    //       faceapi.draw.drawDetections(canvas, this.resizedDetections)
    //       faceapi.draw.drawFaceLandmarks(canvas, this.resizedDetections)
    //       faceapi.draw.drawFaceExpressions(canvas, this.resizedDetections)
    //     }, 1000)
    var canvas_bg = document.createElement("canvas");
    canvas_bg.width = this.video.nativeElement.width;
    canvas_bg.height = this.video.nativeElement.height;
    document.getElementById("camera").append(canvas_bg);
    //document.body.append(canvas_bg)
    var ctx_bg = canvas_bg.getContext('2d');
    // ctx_bg.fillStyle = "rgb(0,0,0)";
    // ctx_bg.fillRect(0, 0, video.width, video.height/2);

    var canvas_face = document.createElement("canvas");
    canvas_face.width = this.video.nativeElement.width;
    canvas_face.height = this.video.nativeElement.height;
    var ctx_face = canvas_face.getContext('2d');

    const canvas = faceapi.createCanvas(this.video.nativeElement)
    document.getElementById("camera").append(canvas);
    const displaySize = { width: this.video.nativeElement.width, height: this.video.nativeElement.height }
    faceapi.matchDimensions(canvas, displaySize)

    var t1 = performance.now();
    var irisC = [];
    let nowBlinking = false;
    let blinkCount = 0;
    let happyCount =0;
    let neutralCount =0

    setInterval(async () => {
      //const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
      const detections = await faceapi.detectAllFaces(this.video.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
      const resizedDetections = faceapi.resizeResults(detections, displaySize)
      this.neutralDetections = faceapi.resizeResults(detections, displaySize);
      console.log('dections..',this.neutralDetections);
      if(this.neutralDetections[0].expressions.happy>0.5){
        const hpyCnt:any =   happyCount += 1;
        localStorage.setItem('happyCount',hpyCnt)
      }
      if(this.neutralDetections[0].expressions.neutral>0.2){
        const ntrCnt:any =   neutralCount += 1;
        localStorage.setItem('neutralCount',ntrCnt)
      }
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)


      faceapi.draw.drawDetections(canvas, resizedDetections)
      faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
      //faceapi.draw.drawFaceExpressions(canvas, resizedDetections)

      //console.log(resizedDetections);

      const landmarks = resizedDetections[0].landmarks;
      const landmarkPositions = landmarks.positions;

      //--- Iric mark ---//
      ctx_bg.clearRect(0, 0, canvas_bg.width, canvas_bg.height)
      var x_ = landmarkPositions[38 - 1].x
      var y_ = landmarkPositions[38 - 1].y
      var w_ = landmarkPositions[39 - 1].x - landmarkPositions[38 - 1].x
      var h_ = landmarkPositions[42 - 1].y - landmarkPositions[38 - 1].y
      ctx_bg.fillStyle = "";
      ctx_bg.fillRect(x_, y_, w_, h_)

      x_ = landmarkPositions[44 - 1].x
      y_ = landmarkPositions[44 - 1].y
      w_ = landmarkPositions[45 - 1].x - landmarkPositions[44 - 1].x
      h_ = landmarkPositions[48 - 1].y - landmarkPositions[44 - 1].y
      ctx_bg.fillRect(x_, y_, w_, h_)

      //--- Face mask ---//
      // ctx_bg.fillStyle = rgb(0,200,0)
      //ctx_bg.fillStyle = 'rgb(0,0,0,0)';
      ctx_bg.fillStyle = "rgba(0, 0, 0, 0.5)";
      ctx_bg.fillStyle = ''
      ctx_bg.beginPath();
      ctx_bg.moveTo(landmarkPositions[0].x, landmarkPositions[0].y);
      for (var i = 1; i < 17; i++) {
        ctx_bg.lineTo(landmarkPositions[i].x, landmarkPositions[i].y);
      }
      ctx_bg.fill();

      ctx_bg.moveTo(landmarkPositions[0].x, landmarkPositions[0].y);
      ctx_bg.lineTo(landmarkPositions[17].x, landmarkPositions[17].y);
      ctx_bg.lineTo(landmarkPositions[27].x, landmarkPositions[17].y);
      ctx_bg.lineTo(landmarkPositions[27].x, landmarkPositions[0].y);
      //console.log('ctx', ctx_bg.lineTo(landmarkPositions[26].x, landmarkPositions[26].y));
      ctx_bg.lineTo(landmarkPositions[16].x, landmarkPositions[16].y);
      ctx_bg.lineTo(landmarkPositions[16].x, landmarkPositions[16].y - 200);
      ctx_bg.lineTo(landmarkPositions[0].x, landmarkPositions[0].y - 200);
      ctx_bg.lineTo(landmarkPositions[0].x, landmarkPositions[0].y);
      ctx_bg.fill();

      //--- Iris value ---//
      ctx_face.clearRect(0, 0, canvas_face.width, canvas_face.height)
      ctx_face.drawImage(this.video.nativeElement, 0, 0, this.video.nativeElement.width, this.video.nativeElement.height);
      var frame = ctx_face.getImageData(0, 0, this.video.nativeElement.width, this.video.nativeElement.height);
      var p_ = Math.floor(x_ + w_ / 2) + Math.floor(y_ + h_ / 2) * this.video.nativeElement.width
      //console.log("eye_RGB:"+[frame.data[p_*4+0], frame.data[p_*4+1], frame.data[p_*4+2]]);
      this.v_ = Math.floor((frame.data[p_ * 4 + 0] + frame.data[p_ * 4 + 1] + frame.data[p_ * 4 + 2]) / 3);
      // console.log("irisC:",this.v_);

      irisC.push(this.v_);
      if (irisC.length > 100) {
        irisC.shift();
      }//

      let meanIrisC = irisC.reduce(function (sum, element) {
        return sum + element;
      }, 0);
      meanIrisC = meanIrisC / irisC.length;
      let vThreshold = 1.5;

      let currentIrisC = irisC[irisC.length - 1];
      if (irisC.length == 100) {
        // console.log('iris',irisC);
        if (nowBlinking == false) {
          if (currentIrisC >= meanIrisC * vThreshold) {

            nowBlinking = true;
            // if (blinkCount <= 3){
              this.toastr.success('eyeBlinked...')
            // }
               let hpycount:any= localStorage.getItem('happyCount');
               let ntrcount:any= localStorage.getItem('neutralCount')
            if (blinkCount == 3 && hpycount >2 && ntrcount >0 ) {
            this.toastr.success('Validating your image and eye Moments')
              this.Capture();
             
              // setInterval(async () => {  

              //   this.onStop()
              // }, 1000)
            }





            // this.toastr.success('eyeBlinked...')
          }//
        }//
        else {
          if (currentIrisC < meanIrisC * vThreshold) {
            nowBlinking = false;

            blinkCount += 1;
            //localStorage.setItem('blink',blinkCount += 1;)

            this.mBlinkSound.pause();
            this.mBlinkSound.currentTime = 0;
            this.mBlinkSound.play();
          }//
        }//

      }//

      // //--- Iris position ---// 36 -> 39
      // let horizontal_eye = [];
      // var x_s = Math.floor( landmarkPositions[36].x )
      // var x_e = Math.floor( landmarkPositions[39].x )
      // var py = Math.floor( landmarkPositions[36].y )
      // for(var x=x_s;x<=x_e;x++){
      //     p_ = x + py * video.width
      //     v_ = (frame.data[p_*4+0] + frame.data[p_*4+1] + frame.data[p_*4+2])/3
      //     horizontal_eye.push(v_)
      // }

      //--- Graph ---//
      ctx_bg.strokeStyle = 'red';
      ctx_bg.lineWidth = 5;
      var Ox = 0;
      var Oy = canvas_bg.height / 2;
      var Lx = canvas_bg.width;
      var Ly = canvas_bg.height / 2;
      var vx = 0 / irisC.length * Lx;
      var vy = irisC[0] / 255 * Ly;
      ctx_bg.beginPath();
      ctx_bg.moveTo(Ox + vx, Oy - vy);
      for (var i = 1; i < irisC.length; i++) {
        vx = i / irisC.length * Lx;
        vy = irisC[i] / 255 * Ly;
        ctx_bg.lineTo(Ox + vx, Oy - vy);
      }
      ctx_bg.stroke();


      //--- mean value x threshold(1.X)
      ctx_bg.strokeStyle = '';
      ctx_bg.lineWidth = 2;
      ctx_bg.beginPath();
      vx = 0 * Lx;
      vy = meanIrisC * vThreshold / 255 * Ly;
      ctx_bg.moveTo(Ox + vx, Oy - vy);
      vx = 1 * Lx;
      ctx_bg.lineTo(Ox + vx, Oy - vy);
      ctx_bg.stroke();

      // //--- Graph ---//
      // Ox = 0;
      // Oy = canvas_bg.height;
      // Lx = canvas_bg.width/2;
      // Ly = canvas_bg.height/2;
      // vx = 0;
      // vy = horizontal_eye[0]/255 * Ly;
      // ctx_bg.beginPath();
      // ctx_bg.moveTo(Ox+vx, Oy-vy);
      // for(var i=1;i<horizontal_eye.length;i++){
      //   vx = i/horizontal_eye.length * Lx;
      //   vy = horizontal_eye[i]/255 * Ly;
      //   ctx_bg.lineTo(Ox+vx, Oy-vy);
      // }
      // ctx_bg.stroke();


      var ctx = canvas.getContext('2d');
      var t2 = performance.now();//ms
      ctx.font = "12px serif";
      ctx.fillText("FPS" + Math.floor(1000.0 / (t2 - t1)), 10, 50);
      //ctx.fillText("FPS:"+ Math.floor(1000.0/(t2-t1)), 10, 50);
      // if(this.v_>103){
      //ctx.fillText("Count:"+blinkCount, 10, 100);
      // }

      // if(blinkCount===3){
      //   this.Capture();

      //   return
      // }
      if (nowBlinking) {
        // if(this.v_>103){
        ctx.fillText("Blinking", 10, 150);
        // }

      }
      //ctx.fillText("FPS:"+ (t2-t1), 10, 50);
      t1 = t2;



    }, 100)

  }

  public Capture() {
    const video: any = document.getElementById("video");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d')
      .drawImage(video, 0, 0, canvas.width, canvas.height);
    this.imageUrl = canvas.toDataURL('image/jpeg');
    if (this.imageUrl) {
      this.byPassLogin();
    }


  }
  onStop() {
    this.video.nativeElement.pause();
    (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
    this.video.nativeElement.srcObject = null;
  }

  ngOnDestroy() {
    (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  byPassLogin() {
    this.activeSpinner();
    if ([null, "", undefined, NaN].includes(this.userInfo['TenantCode'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['userId'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['CourseScheduleId'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['id'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();;
      return false;
    }

    if (this.imageUrl) {
      this.process();
    }

  }
}
