
<div style="display:none !important" id="enableCamera" (click)="start()">Open</div>

<div class="limiter">
    <div class="container-login100" style="background-image: url('../../../assets/images/dhanush/bg-image.png');">
        <div class="wrap-login100">
            <div class="text-center"> <img src="../../../assets/images/dhanush/ouk-logo-new.png" style="width:60%" class="img-fluid" /></div>
            <br>
            <span class="login100-form-title p-b-24"> <span style="font-size:22px;">Proctoring Solution</span> <br>
            <!-- <span style="font-size:15px;">Proctoring Solution</span>  -->
            </span>
            <div style="text-align:center " style="margin:20px 0px !important">
                <div  id="camera">
                    <video #video id="video"  width="280" height="210"  autoplay muted playsinline="true"></video>
                </div>
            </div>
            <!-- <div class="container-login100-form-btn">
                <div class="wrap-login100-form-btn">
                    <div class="login100-form-bgbtn"></div>
                    <button class="login100-form-btn"  (click)="Capture()"><i class="fa fa-sign-in" aria-hidden="true"></i>&nbsp;&nbsp; Login </button>
                </div>
            </div> -->
        
        </div>
       
    </div>
</div>