<!-- <button (click)="capture()">Take Screeny</button>

<br />

<canvas id="fake"></canvas> -->
<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0">Assessments </h5>
                        </div>
                        <div class="card-body" style="margin-bottom: 5px !important;">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="sel1">Course Name : </label>
                                        <select [(ngModel)]='courceId'  id="sel1"
                                            (change)="courceChange()"
                                            class="form-control form-control-sm shadow-sm rounded-lg">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let course of cources" [value]='course.COURSE_ID'>
                                                {{course.COURSE_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Course Schedule :</label>
                                        <!-- [disabled]="isParam" -->
                                        <select [(ngModel)]='schedulId ' id="sel1"
                                            class="form-control form-control-sm shadow-sm rounded-lg"
                                            (change)="schedulChange()">
                                            <option value="" cl [selected]="true">select</option>
                                            <option *ngFor="let schedul of scheduls" [value]='schedul.COURSESHD_ID'>
                                                {{schedul.COURSESHD_NAME}}</option>

                                        </select>


                                    </div>
                                </div>


                            </div>


                        </div>
                        <div *ngIf="table.length" style="overflow-x: auto !important;">
                            <table id="patreggraph" datatable
                                class="table table-striped table-bordered mt-1 pt-1 table-sm small" style="width:100%">
                                <thead class="top bg-info">
                                    <tr>
                                        <th>
                                            Assessment Name</th>
                                        <th>
                                            Date
                                        </th>
                                        <th>Start Time</th>
                                        <th> End Time</th>
                                        <th>No Of Questions</th>
                                        <th>Duration (Mins)</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of table">
                                        <td>{{item.ASSESSMENT_ASSESSMENT_NAME}}</td>
                                        <td>{{item.SA_DATE | date: 'dd/MMMM/yyyy'}}</td>
                                        <td>{{item.SA_START_TIME}}</td>
                                        <td>{{item.SA_END_TIME}}</td>
                                        <td>{{item.ASSESSMENT_NO_OF_QUESTIONS}}</td>
                                        <td>{{item.ASSESSMENT_TIMINGFORASSESSMENT}}</td>
                                        <td>
                                            <button (click)="add(item)" class="btn btn-info btn-sm"
                                                style="color:#fff !important;font-weight: 500 !important;">
                                                <i class="fas fa-chalkboard-teacher"></i> &nbsp;Start Exam</button>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <span data-toggle="modal" data-target="#myModal" id='openpopup'></span>
                    <div class="modal fade" id="myModal" role="dialog" data-backdrop="static" data-keyboard="false">
                        <div class="modal-dialog ">
                            <div class="modal-content">

                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <h5 class="modal-title" style="color: #006e9e !important;"> Online Examination Rules
                                        & Regulations</h5>

                                    <button type="button" (click)="close()" class="close"
                                        data-dismiss="modal">&times;</button>
                                </div>

                                <!-- Modal body -->
                                <div class="modal-body">
                                    <div class="text-warning">  Online exam rules to be followed Strictly</div>
                                    <div *ngFor="let t of termsandcondition;let i=index">

                                  
                                        <div>{{i+1}} {{t.proctoring_desc}}</div>



                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-success" id='md_close' (click)="accept()"
                                        data-dismiss="modal">Accept</button>
                                    <button type="button" class="btn btn-danger" id='md_close' (click)="reject()"
                                        data-dismiss="modal">Reject </button>
                                </div>
                            </div>
                        </div>
                    </div>