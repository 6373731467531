<div class="limiter">
    <div class="container-login100" style="background-image: url('../../../assets/images/dhanush/bg-image.png');">
        <div class="wrap-login100">
            <div class="text-center"> <img src="../../../assets/images/dhanush/ouk-logo-new.png" class="img-fluid" /></div>
            <br>
            <!-- <span class="login100-form-title p-b-24"> <span style="font-size:22px;">Welcome to Samvaad</span> <br> -->
            <span style="font-size:15px;">Proctoring Solution</span> 
            <div style="text-align:center " style="margin:20px 0px !important">
                <div  id="camera" [style.display]="isVideoFlag ? 'none' : 'inline-flex'">
                    <video #video id="video"  width="280" height="210"  autoplay muted playsinline="true"></video>
                </div>
            </div>
            <div class="registerUser" [style.display]="!isVideoFlag ? 'none' : 'flex'">
                <input type="text" class="form-control form-control-sm"   [(ngModel)]="rollno">
            </div>
            <div class="container-login100-form-btn" [style.display]="!isVideoFlag ? 'none' : ''">
                <div class="wrap-login100-form-btn">
                    <div class="login100-form-bgbtn"></div>
                    <button class="login100-form-btn"  (click)="registerUser()"><i class="fa fa-sign-in" aria-hidden="true"></i>&nbsp;&nbsp; Verify </button>
                </div>
            </div>
        
        </div>
       
    </div>
</div>