import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {SessionService} from './session.service'

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  constructor(private router: Router,private sessionService :SessionService,){
  }
  canActivate(): boolean {  
    if (!this.sessionService.gettoken()) {  
        this.router.navigateByUrl("/pnf");  
    }  
  else{
    return this.sessionService.gettoken()
  }
} 
}
