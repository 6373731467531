import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { httpInterceptorProviders } from 'src/interceptor';
import { LoginService } from './services/login.service';
import { DataTablesModule } from 'angular-datatables';
import { CountdownModule } from 'ngx-countdown';
import { SafePipe } from './pipes/safe.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { from } from 'rxjs';
import { BlockCopyPasteDirective } from './../app/directives/block-copy-paste.directive'
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { FooterComponent } from './footer/footer.component';
import { PostassessmentComponent } from './pages/postassessment/postassessment.component';
import { StartexamComponent } from './pages/startexam/startexam.component';
import { MyresultsComponent } from './pages/myresults/myresults.component';
import { FaceLoginComponent } from './pages/face-login/face-login.component';
import { WebcamModule } from 'ngx-webcam';
import * as moment from 'moment';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PhotoSnapComponent } from './pages/photo-snap/photo-snap.component';
import {AudioRecordingService} from 'src/app/services/audio-recording.service';
import { AudioRecComponent } from './pages/audio-rec/audio-rec.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ExpressionLoginComponent } from './pages/expression-login/expression-login.component';
import { TestLoginComponent } from './pages/test-login/test-login.component';
import { VerifyStudentDetailComponent } from './verify-student-detail/verify-student-detail.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { FcLoginComponent } from './pages/fc-login/fc-login.component';
import { LoginPythonComponent } from './pages/login-python/login-python.component';


export function momentAdapterFactory() {
  return adapterFactory(moment);
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    MaincontentComponent,
    LoginComponent,
    HomeComponent,
    FooterComponent,
    SafePipe,
    BlockCopyPasteDirective,
    PostassessmentComponent,
    StartexamComponent,
    MyresultsComponent,
    FaceLoginComponent,
    PageNotFoundComponent,
    PhotoSnapComponent,
    AudioRecComponent,
    ExpressionLoginComponent,
    TestLoginComponent,
    VerifyStudentDetailComponent,
    LandingPageComponent,
    FcLoginComponent,
    LoginPythonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    WebcamModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    HttpClientModule,FormsModule, ReactiveFormsModule, DataTablesModule, CountdownModule, BrowserAnimationsModule, NgxSpinnerModule,
    ToastrModule.forRoot(), NgMultiSelectDropDownModule.forRoot(), AngularEditorModule, ChartsModule, DataTablesModule, NgbModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory })
  ],
  providers: [LoginService, httpInterceptorProviders,AudioRecordingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
// {provide : LocationStrategy , useClass: HashLocationStrategy}