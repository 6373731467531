<!-- 
<div  id="camera">
    <video #video id="video" width="720" height="540" autoplay muted playsinline="true"></video>
</div> -->
<!-- <button (click)="Capture()">Capture</button><br>
<canvas #canvas id="canvas" [width]="WIDTH" [height]="HEIGHT"></canvas> -->

<div class="limiter">
    <div class="container-login100" style="background-image: url('../../../assets/images/dhanush/bg-image.png');">
        <div class="wrap-login100">
            <div class="text-center"> <img src="../../../assets/images/dhanush/ouk-logo-new.png" class="img-fluid" /></div>
            <br>
            <!-- <span class="login100-form-title p-b-24"> <span style="font-size:22px;">Welcome to Samvaad</span> <br> -->
            <span style="font-size:15px;">Proctoring Solution</span> 
            <div style="text-align:center " style="margin:20px 0px !important">
                <div  id="camera">
                    <video #video id="video"  width="720" height="540"  autoplay muted playsinline="true"></video>
                </div>
                <!-- <div>
                    <webcam [width]="280" [height]="303"  *ngIf="!webcamImage" [trigger]="triggerObservable" id="login-video" (imageCapture)="handleImage($event)" (initError)="handleInitError($event)"></webcam>


                </div>
                <div *ngIf="webcamImage" style="margin:20px 0px !important">
                    <img [src]="webcamImage.imageAsDataUrl" width="280px" height="100%" />
                </div> -->
            </div>

            <div class="ft-btn" align="center">
                <!-- <div class="row">
                    <div class="col-12">
                        <button (click)="retake()" *ngIf="webcamImage"><i class="fa fa-camera" aria-hidden="true">&nbsp;&nbsp;</i> Retake</button>
                        <div class="container-login100-form-btn" *ngIf="!webcamImage">
                            <div class="wrap-login100-form-btn" style="width: 50%; padding: 6px 10px !important;">
                                <div class="login100-form-bgbtn"></div>
                                <button (click)="triggerSnapshot()" id="snapshotBtn" style="font-family:Poppins-Medium!important;font-size: 15px!important;color: #fff !important;line-height: 1.2 !important;text-transform: uppercase !important;"><i class="fa fa-camera" aria-hidden="true"></i>&nbsp;&nbsp; Take Photo </button>
                            </div>
                        </div>
                     
                    </div>

                </div> -->
                <!-- <button (click)="Capture()">Capture</button> -->

            </div>

            <!-- <div class="container-login100-form-btn" *ngIf="webcamImage?.imageAsBase64">
                <div class="wrap-login100-form-btn">
                    <div class="login100-form-bgbtn"></div>
                    <button class="login100-form-btn" id="snapshotBtn" (click)="byPassLogin()"><i class="fa fa-sign-in" aria-hidden="true"></i>&nbsp;&nbsp; Login </button>
                </div>
            </div> -->
        </div>
    </div>
</div>