import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProConfigService {
  config:any
  configData: any;
  _configData = new BehaviorSubject<any>(null);
  constructor() { }
getConfigData() {
  return this.configData;
}

setConfigData(data: any) {
  this.configData=data;
      // "Allow_New_Tab":data[0]['Allow New Tab'],
      // "Allow_Multiple_Face_Detection":data[1]['Allow Multiple face deceted'],
      // "Allow_Tough_Face_Not_detected":data[2]['Allow through face not detected'],
      // "Allow_Mobile_Detected":data[3]['Allow Mobile detected'],
      // "Allow_Camera_Disabled":data[4]['Camera Disabled'],

  this._configData.next(this.configData);
}
}
