import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as faceapi from 'face-api.js'
declare var $: any

@Component({
  selector: 'app-face-login',
  templateUrl: './face-login.component.html',
  styleUrls: ['./face-login.component.css']
})
export class FaceLoginComponent implements OnInit {
  public seconds: number;
  isAllowtoExicute: boolean = true;
  data: any = {};
  userInfo: any = {};
  takeExamQueryParams: any = {};
  interval;
  imageUrl: any;
  imagesResp: any;
  redirectoLogin = "https://openuni.samvaadpro.com";
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  resultArray: any = [];
  constructor(private _Activatedroute: ActivatedRoute, private cdr: ChangeDetectorRef, private userService: LoginService, private spinner: NgxSpinnerService, private route: Router, private toastr: ToastrService, private CommonService: CommonService) {
    this._Activatedroute.queryParamMap
      .subscribe((params) => {
        this.userInfo = {
          userId: params.get('userId'),
          TenantCode: params.get('TenantCode'),
          id: params.get('id'),
          CourseScheduleId: params.get('CourseScheduleId'),
        }
        this.takeExamQueryParams = {
          id: params.get('id'),
          CourseScheduleId: params.get('CourseScheduleId'),
        }

      }


      );
      this.getStudentImages();
  }

  async ngOnInit() {
   Promise.all([
      faceapi.nets.faceRecognitionNet.loadFromUri('assets/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('assets/models'), ,
      faceapi.nets.ssdMobilenetv1.loadFromUri('assets/models'),
    ]).then((async () => this.start()));
    if (localStorage.getItem('TenantCode') || localStorage.getItem('Username') || localStorage.getItem('CourseScheduleId')) {
      localStorage.clear();
    }
  }
 async getStudentImages(){
    const payload = { TENANT_CODE: this.userInfo.TenantCode, RollNumber: this.userInfo.userId, ATTENDANCE_COURSE_SCHEDULE_ID: this.userInfo.CourseScheduleId }
    await this.CommonService.verifyFace(payload).subscribe(res => {
      this.imagesResp = res.FRImages;

      if(this.imagesResp.length==0){

        this.toastr.error('Photo Not Found, Please Contact LMS Administrator');
        localStorage.clear();
        window.open(this.redirectoLogin, "_self")
      }
    })
  }
  async start() {
    //debugger
    if (this.imageUrl) {
      const canvas = require("canvas");
      const labeledFaceDescriptors = await this.loadLabeledImages();
      console.log('labeledFaceDescriptors',labeledFaceDescriptors);
      const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors, 0.6)
      console.log('faceMatcher',faceMatcher)
      let image = await canvas.loadImage(this.imageUrl);
      const displaySize = { width: image.width, height: image.height }
      faceapi.matchDimensions(canvas, displaySize)
      const detections = await faceapi.detectAllFaces(image).withFaceLandmarks().withFaceDescriptors()
      const resizedDetections = faceapi.resizeResults(detections, displaySize)
      const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor));
      if (results.length > 1) {
        this.toastr.error('More than one person detected, Please try again');
        this.deactivateSpinner()
        return false;
      }

      if (results.length === 1 && results[0]['distance']<0.5 && results[0]['label'] === 'Photo Matched') {
        this.deactivateSpinner();
        sessionStorage.setItem('duplicateSession', '1');
        this.CommonService.userId = this.userInfo.userId;
        localStorage.setItem('loginurl', this.route.url);
        localStorage.setItem('UserId', this.userInfo.userId);
        localStorage.setItem('TenantCode', this.userInfo.TenantCode)
        localStorage.setItem('id', this.userInfo.id)
        localStorage.setItem('CourseScheduleId', this.userInfo.CourseScheduleId);
        localStorage.setItem('isLoggedIn', 'true');
        this.toastr.success('User Photo Match, Login Sucessful');
        localStorage.setItem('FRImages', JSON.stringify(this.imagesResp))
        this.route.navigateByUrl('home/postassessment')
      }
      if (results.length === 1 && results[0]['distance']>0.5 && results[0]['label'] === 'Photo Matched') {
        this.toastr.error('Photo not matched,Login is invalid');

        this.deactivateSpinner()
          this.retake();
        return false;
      }
      if (results.length === 1 && results[0]['label'] === 'unknown') {
        this.toastr.error('Photo not matched,Login is invalid');

        this.deactivateSpinner()
        this.retake();
        return false;

      }
    }
   // debugger
  }

  loadLabeledImages() {

    if (this.imagesResp && this.imagesResp.length>0) {
      const labels = ['Photo Matched']
      return Promise.all(
        labels.map(async label => {
          const descriptions = []
          for (let i = 0; i < this.imagesResp.length; i++) {
            const element = this.imagesResp;
            const cnt = 'data:image/jpeg;base64,'
            const images = element[i].Image;
            if (element && images) {
              const img = await faceapi.fetchImage(images);
              const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
              descriptions.push(detections.descriptor)
            }


          }

          return new faceapi.LabeledFaceDescriptors(label, descriptions)


        })
      )
    }
    else if(this.imagesResp.length==0) {
      this.toastr.error('Photos not found, Please Contact LMS Adminstrator');

      setInterval(res =>{
        window.open(this.redirectoLogin, "_self")
        localStorage.clear();
      },1000)
    
      this.deactivateSpinner();
      return
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();

  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.activeSpinner();
    this.webcamImage = webcamImage;
    this.imageUrl = this.webcamImage.imageAsDataUrl;
    this.deactivateSpinner();
  }

  byPassLogin() {
    this.activeSpinner();
    if ([null, "", undefined, NaN].includes(this.userInfo['TenantCode'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['userId'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['CourseScheduleId'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['id'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      this.deactivateSpinner();;
      return false;
    }

    if (this.imageUrl) {
      this.start();
    }

  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      alert(`Camera access was not allowed by user! 
      Please Allow Camera Access to Login.`);
    }
  }
  init() {
    let self = this;
    $('.input100').each(function () {
      $(this).on('blur', function () {
        if ($(this).val().trim() != "") {
          $(this).addClass('has-val');
        }
        else {
          $(this).removeClass('has-val');
        }
      })
    })

    var input = $('.validate-input .input100');

    $('.validate-form').on('submit', function () {
      var check = true;

      for (var i = 0; i < input.length; i++) {
        if (validate(input[i]) == false) {
          showValidate(input[i]);
          check = false;
        }
      }
      if (check) {
        self.byPassLogin()
      }
      return check;
    });


    $('.validate-form .input100').each(function () {
      $(this).focus(function () {
        hideValidate(this);
      });
    });

    function validate(input) {
      if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
        return true
        if ($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
          return false;
        }
      }
      else {
        if ($(input).val().trim() == '') {
          return false;
        }
      }
    }

    function showValidate(input) {
      var thisAlert = $(input).parent();

      $(thisAlert).addClass('alert-validate');
    }

    function hideValidate(input) {
      var thisAlert = $(input).parent();

      $(thisAlert).removeClass('alert-validate');
    }

    var showPass = 0;
    $('.btn-show-pass').on('click', function () {
      if (showPass == 0) {
        $(this).next('input').attr('type', 'text');
        $(this).find('i').removeClass('zmdi-eye');
        $(this).find('i').addClass('zmdi-eye-off');
        showPass = 1;
      }
      else {
        $(this).next('input').attr('type', 'password');
        $(this).find('i').addClass('zmdi-eye');
        $(this).find('i').removeClass('zmdi-eye-off');
        showPass = 0;
      }

    });

  }
  retake() {
    this.getStudentImages();
    this.webcamImage = null;
    

  }
  // navigate() {
  //   this.route.navigate([this.url], { queryParams: this.takeExamQueryParams})
  // }


}
