<!-- <ngx-spinner></ngx-spinner> -->
<ngx-spinner bdColor="rgba(0,0,0,0.1)" size="medium" color="#464343" type="ball-spin-clockwise"></ngx-spinner>
<!-- 

<app-webcam-snapshot></app-webcam-snapshot> -->
<!-- <button (click)="capture()">Take Screeny</button>

<br />

<canvas id="fake"></canvas> -->
<router-outlet></router-outlet>