import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { HomeComponent } from './pages/home/home.component';
import { PostassessmentComponent } from './pages/postassessment/postassessment.component';
import { StartexamComponent } from './pages/startexam/startexam.component';
import { MyresultsComponent } from './pages/myresults/myresults.component';
import { FaceLoginComponent } from './pages/face-login/face-login.component';
import { AuthGuard } from './services/auth.guard';
import {CanDeactivateGuard} from './services/can-deactivate.guard'
import {SessionGuard} from './services/session.guard'
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { AppComponent } from './app.component';
import { ExpressionLoginComponent } from './pages/expression-login/expression-login.component';
import { TestLoginComponent } from './pages/test-login/test-login.component';
import { VerifyStudentDetailComponent } from './verify-student-detail/verify-student-detail.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { FcLoginComponent } from './pages/fc-login/fc-login.component';
const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,canActivate: [AuthGuard,SessionGuard],
  
    children: [
      {
        path: 'dashboard',
        component: MaincontentComponent,canActivate: [AuthGuard,SessionGuard]
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
   
      {
        path: 'postassessment',
        component: PostassessmentComponent,canActivate: [AuthGuard,SessionGuard],
      },
      {
        path: 'myresults',
        component: MyresultsComponent,canActivate: [AuthGuard,SessionGuard]
      },
      {
        path: 'takeExam',
        component: StartexamComponent,canActivate: [AuthGuard,SessionGuard]
      }

    ],
  },
  {
    path: '',
    redirectTo: 'verify',
    pathMatch: 'full'
  },

  //   {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full'
  // },
  {
    path: 'login',
    component: TestLoginComponent
  },
  {
    path: 'verify',
    component: VerifyStudentDetailComponent
  },
  {
    path: 'test',
    component: ExpressionLoginComponent
  },
  {
    path: 'pnf',
    component: PageNotFoundComponent
  },
  {
    path: 'Home/dashboard',
    redirectTo: 'home',
  },
  {
    path: '**',
    redirectTo: 'login'
  },
  // {path: '', component: AppComponent, canDeactivate: [CanDeactivateGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

//  canActivate: [AuthGuard] resolve:{menu:Resolver},
