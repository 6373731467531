// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serviceUrl:"https://oukinternal.samvaadpro.com/api/",
  lmsHostUrl:"https://oukinternal.samvaadpro.com/",
  recognitionUrl:"https://proctoring.samvaad.pro:8000/services/",
  faceverifyUrl:'https://oukinternal.samvaadpro.com/api/FaceRec/',
  //https://erp.samvaadpro.com/
  //https://proctoring.samvaad.pro:8000/services/
  //http://127.0.0.1:8000/services/user/traditional/recognition
  //https://proctoring.samvaad.pro:8000/services/user/face_moments
  //recognitionUrl:"https://proctoring.samvaad.pro:8000/services/",
  fileUrl: "/api",
  SOCKET_ENDPOINT: 'http://localhost:3000',

    ENDPOINTS:{
      RECOGNITION:'recognition'

    },
    MESSAGES:{
      OK:'OK',
      NOTOK:'NotOK'
    }

};

//http://localhost:56608/swagger/index.html
//  serviceUrl:"http://lmsav.dhanushinfotech.com/",https://openuni.samvaadpro.com

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
