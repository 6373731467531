import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare function googleTranslate():any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  userName:string=localStorage.getItem('Username')
  constructor(private route: Router,@Inject(DOCUMENT) private document: any,) { }
  ngAfterViewInit(): void {
    //this.openFullscreen();
  }
  elem
  ngOnInit(): void {
    googleTranslate();
    this.elem = document.documentElement;
    //this.openFullscreen();
   
  }
  Signout(){
    localStorage.clear();
    this.route.navigate(['/login'])
  }
  menuClick(){
    $('#sidebar').toggleClass('slide');
  }
  // openFullscreen() {
  //   if (this.elem.requestFullscreen) {
  //     this.elem.requestFullscreen();
  //   } else if (this.elem.mozRequestFullScreen) {
  //     /* Firefox */
  //     this.elem.mozRequestFullScreen();
  //   } else if (this.elem.webkitRequestFullscreen) {
  //     /* Chrome, Safari and Opera */
  //     this.elem.webkitRequestFullscreen();
  //   } else if (this.elem.msRequestFullscreen) {
  //     /* IE/Edge */
  //     this.elem.msRequestFullscreen();
  //   }
  // }

  // /* Close fullscreen */
  // closeFullscreen() {
  //   if (document.exitFullscreen) {
  //     this.document.exitFullscreen();
  //   } else if (this.document.mozCancelFullScreen) {
  //     /* Firefox */
  //     this.document.mozCancelFullScreen();
  //   } else if (this.document.webkitExitFullscreen) {
  //     /* Chrome, Safari and Opera */
  //     this.document.webkitExitFullscreen();
  //   } else if (this.document.msExitFullscreen) {
  //     /* IE/Edge */
  //     this.document.msExitFullscreen();
  //   }
  // }

}
