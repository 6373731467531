import { Component, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as faceapi from 'face-api.js'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import customProtocolCheck from "custom-protocol-check";

@Component({
  selector: 'app-fc-login',
  templateUrl: './fc-login.component.html',
  styleUrls: ['./fc-login.component.css']
})
export class FcLoginComponent implements OnInit {
  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;
  v_
  @ViewChild('canvas')
  public canvas: ElementRef
  interval
  videoInterval
  neutralDetections
  userInfo: any = {};
  takeExamQueryParams: any = {};
  imageUrl: any;
  hpyCnt: any = 0
  ntrCnt: any = 0
  imagesResp: any = [];
  cnt = 0;
  redirectoLogin = "https://openuni.samvaadpro.com";
  stopinterVal: boolean = false;
  constructor(@Inject(PLATFORM_ID) private _platform: Object, private toastr: ToastrService, private _Activatedroute: ActivatedRoute, private cdr: ChangeDetectorRef, private userService: LoginService, private CommonService: CommonService, private spinner: NgxSpinnerService, private route: Router) {
    this._Activatedroute.queryParamMap
      .subscribe((params) => {
        this.userInfo = {
          userId: params.get('userId'),
          TenantCode: params.get('TenantCode'),
          id: params.get('id'),
          CourseScheduleId: params.get('CourseScheduleId'),
        }
        this.takeExamQueryParams = {
          id: params.get('id'),
          CourseScheduleId: params.get('CourseScheduleId'),
        }

      }


      );

    this.getStudentImages();

  }

  async ngOnInit() {
    if ([null, "", undefined, NaN].includes(this.userInfo['TenantCode'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['userId'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['CourseScheduleId'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      return false;
    }
    if ([null, "", undefined, NaN].includes(this.userInfo['id'])) {
      this.toastr.error("Invalid URL or Login Details. Please contact Administrator.");
      return false;
    }

    Promise.all([
      faceapi.nets.faceRecognitionNet.loadFromUri('/assets/fc/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/assets/fc/models'),
      faceapi.nets.ssdMobilenetv1.loadFromUri('/assets/models'),
      faceapi.nets.tinyFaceDetector.loadFromUri('assets/models'),
      faceapi.nets.faceExpressionNet.loadFromUri('assets/models')
    ])
    if (isPlatformBrowser(this._platform) && 'mediaDevices' in navigator) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((ms: MediaStream) => {
        const _video = this.video.nativeElement;
        _video.srcObject = ms;
        _video.play().then(res => {
          var canvas_bg = document.createElement("canvas");
          canvas_bg.width = this.video.nativeElement.width;
          canvas_bg.height = this.video.nativeElement.height;
          document.getElementById("camera").append(canvas_bg);
          var canvas_face = document.createElement("canvas");
          canvas_face.width = this.video.nativeElement.width;
          canvas_face.height = this.video.nativeElement.height;
          const canvas = faceapi.createCanvas(this.video.nativeElement)
          document.getElementById("camera").append(canvas);
          const displaySize = { width: this.video.nativeElement.width, height: this.video.nativeElement.height }
          faceapi.matchDimensions(canvas, displaySize)
          this.videoInterval = setInterval(async () => {
            const detections = await faceapi.detectAllFaces(this.video.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
            if (detections.length > 1) {
              this.toastr.error('More than one Face Detected...');
              window.location.reload();
            }
            const resizedDetections = faceapi.resizeResults(detections, displaySize)
            this.neutralDetections = faceapi.resizeResults(detections, displaySize);
            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
            faceapi.draw.drawDetections(canvas, resizedDetections)
            faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
            let lneutralCnt;
            if (this.neutralDetections[0]?.expressions?.neutral > 0.2) {
              this.cnt = 0;
              if (this.ntrCnt < 20) {
                this.ntrCnt += 1;
                localStorage.setItem('neu', this.ntrCnt);
                lneutralCnt = parseInt(localStorage.getItem('neu'));
              }

            }

            if (this.ntrCnt < 20 && this.neutralDetections[0]?.expressions?.happy > 0.5 && this.cnt == 0) {
              this.toastr.error('Please Dont Smile, Untill System asks you to smile');
              this.cnt++;
              return;
            }
            if (lneutralCnt == 20) {
              this.toastr.success('Please smile to check your face Moments')
              return;
            }

            if (this.ntrCnt == 20) {
              if (this.neutralDetections[0]?.expressions?.happy > 0.5) {
                if (this.hpyCnt < 20) {
                  this.hpyCnt += 1;
                  localStorage.setItem('hp', this.hpyCnt)
                }
              }


            }
            if (this.ntrCnt > 15 && this.hpyCnt > 9) {
              if (this.hpyCnt == 10) {
                this.toastr.success('Please wait validation your photo and moments')
                this.Capture();
              }

              return;
            }
          })
        });
      })

    }

  }

  async start() {
    const labeledFaceDescriptors = await this.loadLabeledImages()
    const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors, 0.6)
    let image = this.imageUrl
    let canvas = require("canvas");
    image = await canvas.loadImage(this.imageUrl);
    canvas = faceapi.createCanvasFromMedia(image)
    const displaySize = { width: image.width, height: image.height }
    faceapi.matchDimensions(canvas, displaySize)
    const detections = await faceapi.detectAllFaces(image).withFaceLandmarks().withFaceDescriptors()
    const resizedDetections = faceapi.resizeResults(detections, displaySize)
    const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor));

    if(results.length==1){
      if(results[0]['distance'] <= 0.5){
        // alert('matched.....')
        this.deactivateSpinner();
        sessionStorage.setItem('duplicateSession', '1');
        this.CommonService.userId = this.userInfo.userId;
        localStorage.setItem('loginurl', this.route.url);
        localStorage.setItem('UserId', this.userInfo.userId);
        localStorage.setItem('TenantCode', this.userInfo.TenantCode)
        localStorage.setItem('id', this.userInfo.id)
        localStorage.setItem('CourseScheduleId', this.userInfo.CourseScheduleId);
        localStorage.setItem('isLoggedIn', 'true');
        this.toastr.success('User Photo Match, Login Sucessful');
        localStorage.setItem('FRImages', JSON.stringify(this.imagesResp))
        this.route.navigateByUrl('home')

  
      }
      else {
        this.toastr.error('Photo not matched,Login is invalid');
        this.toastr.error('Photo not matched,Login is invalid');
        setInterval(res => {
          window.location.reload();
        }, 1000)
        this.deactivateSpinner()
        return false;
      }
    }

    // if(results[0].distance<=0.5){
    
    // }

    return;
    // if (results.length > 1) {
    //   this.toastr.error('More than one person detected, Please try again');
    //   window.location.reload();
    //   this.deactivateSpinner()
    //   return false;
    // }

    if (results.length === 1 && results[0]['distance'] < 0.5 && results[0]['label'] === 'Photo Matched') {
      this.deactivateSpinner();
      sessionStorage.setItem('duplicateSession', '1');
      this.CommonService.userId = this.userInfo.userId;
      localStorage.setItem('loginurl', this.route.url);
      localStorage.setItem('UserId', this.userInfo.userId);
      localStorage.setItem('TenantCode', this.userInfo.TenantCode)
      localStorage.setItem('id', this.userInfo.id)
      localStorage.setItem('CourseScheduleId', this.userInfo.CourseScheduleId);
      localStorage.setItem('isLoggedIn', 'true');
      this.toastr.success('User Photo Match, Login Sucessful');
      localStorage.setItem('FRImages', JSON.stringify(this.imagesResp))
      this.route.navigateByUrl('home')
    }
    if (results.length === 1 && results[0]['distance'] > 0.5 && results[0]['label'] === 'Photo Matched') {
      this.toastr.error('Photo not matched,Login is invalid');
      setInterval(res => {
        window.location.reload();
      }, 1000)
      this.deactivateSpinner()
      return false;
    }
    if (results.length === 1 && results[0]['label'] === 'unknown') {
      this.toastr.error('Photo not matched,Login is invalid');
      setInterval(res => {
        window.location.reload();
      }, 1000)
      this.deactivateSpinner()
      return false;

    }


  }

  async getStudentImages() {
    const payload = { TENANT_CODE: this.userInfo.TenantCode, RollNumber: this.userInfo.userId, ATTENDANCE_COURSE_SCHEDULE_ID: this.userInfo.CourseScheduleId }
    await this.CommonService.verifyFace(payload).subscribe(res => {
      this.imagesResp = res.FRImages;
      if (this.imagesResp.length == 0) {
        window.open(this.redirectoLogin, "_self")
        this.toastr.error('Photo Not Found, Please Contact LMS Administrator');
        localStorage.clear();

      }
    })
  }

  loadLabeledImages() {
    const labels = ['Photo Matched']
    return Promise.all(
      labels.map(async label => {
        const descriptions = []
        for (let i = 0; i < this.imagesResp.length; i++) {
          const element = this.imagesResp;
          if (element[i].Image.includes('data:image/jpeg;base64,')) {
            const images = element[i].Image;
            if (element && images) {
              const img = await faceapi.fetchImage(images);
              const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
              descriptions.push(detections.descriptor)
            }

          }
          else {
            const cnt = 'data:image/jpeg;base64,'
            const images = cnt + element[i].Image;
            if (element && images) {
              const img = await faceapi.fetchImage(images);
              const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
              descriptions.push(detections.descriptor)
            }

          }


        }

        return new faceapi.LabeledFaceDescriptors(label, descriptions)


      })
    )
  }

  public Capture() {
    const video: any = document.getElementById("video");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d')
      .drawImage(video, 0, 0, canvas.width, canvas.height);
    this.imageUrl = canvas.toDataURL('image/jpeg');
    this.start();
    clearInterval(this.videoInterval);

  }
  onStop() {
    this.video.nativeElement.pause();
    (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
    this.video.nativeElement.srcObject = null;
  }

  ngOnDestroy() {
    (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }



}
