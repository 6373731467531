import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private loginSerice :LoginService,private router: Router) {}  
  canActivate(): boolean {  
      if (!this.loginSerice.gettoken()) {  
          this.router.navigateByUrl("/pnf");  
      }  
    else{
      return this.loginSerice.gettoken()
    }
  } 
  
}
