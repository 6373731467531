import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { AudioRecordingService } from 'src/app/services/audio-recording.service';
import { CommonService } from 'src/app/services/common.service';
import { ProConfigService } from 'src/app/services/pro-config.service';

@Component({
  selector: 'app-photo-snap',
  templateUrl: './photo-snap.component.html',
  styleUrls: ['./photo-snap.component.css']
})
export class PhotoSnapComponent implements OnInit {
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  allowCam:boolean
  @Output()
  camerDis = new EventEmitter<string>();
  constructor(private configService:ProConfigService,) { 
      this.configService._configData.subscribe(data =>{
        this.allowCam = data['Allow_Camera_Disabled']
     })
    }

  ngOnInit() {
   
  }
 
  public triggerSnapshot(): void {
        this.trigger.next(); 
  }
 

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      if(this.allowCam==false){
        localStorage.setItem('camerDisabled','disbaled');
        this.camerDis.emit('true');
        alert(`Camera access was not allowed by user!Please Allow Camera Access to Login...`);
      }
      
 
     
    }
  }

}
