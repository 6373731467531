import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { environment } from './../../environments/environment'
import { constants } from '../constants'
import { BehaviorSubject } from "rxjs";
const url = environment.serviceUrl;
const rurl = environment.recognitionUrl;
const fv = environment.faceverifyUrl;
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  assessment: any = {}
  userId = localStorage.getItem('UserId');
  appComponent: AppComponent;
  spinnerCount: number = 0;
  fileUrl: string = environment.fileUrl;
  configData: any;
  _configData = new BehaviorSubject<any>(null);

  public fileObs: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  constructor(public http: HttpClient,) { }

  postCall(route, data): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = url + apiUrl;
    return this.http.post<any>(uri, data)
  }

  /////////////////////////////////////spinner/////////////////////////////////////////


  activateSpinner() {
    this.spinnerCount++;
    if (this.spinnerCount == 1) {
      this.appComponent.activateSpinner()

    }
  }

  deactivateSpinner() {
    if (!this.spinnerCount) return
    this.spinnerCount--
    if (!this.spinnerCount) this.appComponent.deactivateSpinner();
    if (this.spinnerCount < 0) {
      this.spinnerCount = 0;
      this.appComponent.deactivateSpinner()
    }

  }

  /////////////////////////////////////spinner///////////////////////////////////////

  ///////////////////////////////////post,put and get //////////////////////////////////



  getEditorData(arg0: string, payload: { TENANT_CODE: number; }) {
    let apiUrl = constants[arg0] || arg0
    let finalUrl = url + apiUrl;
    return this.http.post(finalUrl, payload);
  }



  getCall(route): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = url + apiUrl;
    return this.http.get(uri)
  }
  getCallWithId(route, id): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = url + apiUrl;
    return this.http.get(uri + '/' + id)
  }


  getCallWithParams(route, data) {
    let apiUrl = constants[route] || route
    let uri = url + apiUrl;
    return this.http.get(uri, { params: data })
  }
  deleteCall(route, data): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = url + apiUrl;
    return this.http.delete<any>(uri, { params: data })
  }

  ///////////////////////////////////post,put and get //////////////////////////////////


  getCourseSchedule(data) {
    data.UserId = this.userId;
    // data.RoleId = localStorage.RoleId
    let apiUrl = constants['GetCourseSchedule'] || 'GetCourseSchedule'
    let uri = url + apiUrl
    return this.http.post(uri, data)

  }

  getCourses() {
    let apiUrl = constants['GetCourses'] || 'GetCourses'
    let uri = url + apiUrl + '/' + this.userId;
    let id = localStorage.RoleId
    return this.http.get(uri + '/' + id)
  }

  ///////get cources by admin//////////// http://localhost:50905/GetAdminCourses/68664158/2/51964213/////////////

  getAdminCourses(tnt_code?) {
    let apiUrl = constants['GetAdminCourses'] || 'GetAdminCourses'
    let uri = url + apiUrl + '/' + this.userId;
    let id = localStorage.RoleId
    let code = tnt_code ?? localStorage.getItem('TenantCode');
    return this.http.get(uri + '/' + id + '/' + code)
  }
  ///////get cources by admin////////////


  /////////////////GetAdminCourseSchedule///////////////////////////////////////////

  /////////////////////my results///////////////

  getResults() {
    let apiUrl = constants['StudentAssessementResult'] || 'StudentAssessementResult'
    let uri = url + apiUrl + '/' + this.userId;
    return this.http.get(uri)
  }

  /////////////////////my results///////////////

  //////////////////////////get Post Assessments/////////////////////
  getAssessments(data) {
    data.UserId = this.userId;
    let uri = url + 'Assessment/ListOfAssessments'
    return this.http.post(uri, data)
  }
  //////////////////////////get Post Assessments/////////////////////





  /////////////////////////////////takeExam//////////////////////     
  takeExam(data) {
    // let uri = url + 'GetAssessment'
    let apiUrl = constants['GetAssessment'] || 'GetAssessment'
    let uri = url + apiUrl;
    return this.http.post(uri, data)
  }

  /////////////////////////////////takeExam//////////////////////     


  /////////////////////////// SetAssessments/////////////////////////////
  setAssessments(data) {
    data.UserId = this.userId;
    // let uri = url + 'SetAssessments'
    let uri = url + constants['SetAssessments'] || 'SetAssessments'
    return this.http.post(uri, data)
  }
  /////////////////////////// SetAssessments/////////////////////////////

  ////////////////////////// check answers//////////////////////////////
  checkAnswers(id, saId) {
    let apiUrl = constants['CheckAnswers'] || 'CheckAnswers'
    let uri = url + apiUrl + '/' + id + '/' + this.userId + '/' + saId
    return this.http.get(uri)
  }

  ////////////////////////// check answers//////////////////////////////



  /////////////////////////////////recognition//////////////////////     
  recognition(data) {
    let apiUrl = constants['traditional/recognition'] || 'traditional/recognition'
    let uri = rurl + apiUrl;
    return this.http.post(uri, data)
  }

  /////////////////////////////////recognition//////////////////////

  // https://proctoring.samvaad.pro:8000/services/user/modern/recognition
  /////////////////////////////////face_moments//////////////////////     
  imageCapture(data) {
    let apiUrl = constants['face_moments'] || 'face_moments'
    let uri = rurl + apiUrl;
    return this.http.post(uri, data)

  }

  /////////////////////////////////recognition//////////////////////    


  /////////////////////////////////audio_recording//////////////////////     
  audioCapture(data) {
    let apiUrl = constants['audio_recording'] || 'audio_recording'
    let uri = rurl + apiUrl;
    return this.http.post(uri, data)

  }

  /////////////////////////////////recognition//////////////////////   
  /////////////////////////////////audio_recording//////////////////////     
  //  verifyFace(data) {
  //   let apiUrl=constants['StudentsListByIDNumber']||'StudentsListByIDNumber'
  //   let uri = fv + apiUrl;
  //   return this.http.post(uri, data)

  // }

  verifyFace(data): Observable<any> {
    let apiUrl = constants['StudentsListByIDNumber'] || 'StudentsListByIDNumber'
    let uri = fv + apiUrl;
    return this.http.post<any>(uri, data)
  }
  // InsertFrData
  InsertFrData(data): Observable<any> {
    let apiUrl = constants['InsertFrData'] || 'InsertFrData'
    let uri = fv + apiUrl;
    return this.http.post<any>(uri, data)
  }

  proctoringConfig(data): Observable<any> {
    let apiUrl = constants['proctoringConfig'] || 'proctoringConfig'
    let uri = url + apiUrl;
    return this.http.post<any>(uri, data)
  }
  /////////////////////////////////recognition//////////////////////   


}
//--skipTests=true