import { Component, OnInit, OnDestroy, Inject, HostListener, ViewChild } from '@angular/core';
import { CommonService } from './../../services/common.service'
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { SidebarComponent } from 'src/app/sidebar/sidebar.component';
import { ProConfigService } from 'src/app/services/pro-config.service';

@Component({
  selector: 'app-postassessment',
  templateUrl: './postassessment.component.html',
  styleUrls: ['./postassessment.component.css']
})
export class PostassessmentComponent implements OnInit {

  cources: [] = [];
  courceId: string = ''
  schedulId: string | number = '';
  scheduls: [] = [];
  table: Array<any> = [];
  isParam: boolean = false;
  currentURL;
  interval: any;
  isAllowExam: any;
  startExamData: any;
  elem;
  cf
  @ViewChild(SidebarComponent) child;
  termsandcondition: any;
  roleId: any;
  userId: any;
  constructor(private configService: ProConfigService, private CommonService: CommonService, @Inject(DOCUMENT) private document: any, private route: Router, private active: ActivatedRoute, private toastr: ToastrService) {
    // this.active.queryParams.subscribe((res) => {
    // if (localStorage.getItem('id') && localStorage.getItem('CourseScheduleId')) {
    //   this.courceId = localStorage.getItem('id') ? localStorage.getItem('id') : '';
    //   this.schedulId = localStorage.getItem('CourseScheduleId') ? localStorage.getItem('CourseScheduleId') : '';
    //   this.isParam = (this.courceId && this.schedulId) ? true : false;
    //   if (this.courceId) {
    //     this.courceChange();
    //   }
    //   if (this.courceId && this.schedulId) {
    //     this.schedulChange();
    //   }
    // }
    // // })
    active.queryParams.subscribe((res) => {
      if (res) {
        this.courceId = res.Id ? res.Id : '';
        this.schedulId = res.CourseScheduleId ? res.CourseScheduleId : '';
        this.isParam = (this.courceId && this.schedulId) ? true : false;
        if (this.courceId) {
          this.courceChange();
        }
        if (this.courceId && this.schedulId) {
          this.schedulChange();
        }
      }
    })
    this.getCourses();

    //   document.querySelector("input").addEventListener("keydown",function(e){
    //     console.log(e.which);
    //     if (e.which == 27){
    //          alert("Escape pressed!");
    //         return false;
    //     }
    // });

  }
  descendants: boolean;
  first: boolean;
  read: any;
  isViewQuery: boolean;
  selector: any;
  static?: boolean;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    alert('esc pressed')
  }

  async ngOnInit() {
    this.roleId=localStorage.getItem('RoleId');
    this.userId=localStorage.getItem('UserId');
    localStorage.removeItem('camerDisabled');
    this.elem = document.documentElement;
    localStorage.setItem('isExamStarted', 'false');
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getCourses() {
    this.activeSpinner();
    this.CommonService.getCourses().subscribe((res: any) => {
      this.deactivateSpinner();
      this.cources = res
    }, e => { this.deactivateSpinner(); })
  }

  courceChange() {
   // alert('userID'+localStorage.getItem('UserId'))
    this.activeSpinner();
    let data = {
      "CourseId": this.courceId,
      // "RoleId":this.roleId,
      "UserId":this.userId


    }
    this.CommonService.getCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner();
      this.scheduls = res;
    }, e => { this.deactivateSpinner(); })
  }
  schedulChange() {
    this.activeSpinner();
    let data = {
      CourseScheduleId: this.schedulId,
      CourseId: this.courceId
    }
    this.CommonService.getAssessments(data).subscribe((res: any) => {
      this.table = res;
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(); })
  }


  startExam(data) {
    var date = new Date();
    let starttime: string[] = data.SA_START_TIME.split(':');
    let endTime: string[] = data.SA_END_TIME.split(':');
    let [t1, t2, time] = [this.getDefaultDateFromTime(+starttime[0], +starttime[1]), this.getDefaultDateFromTime(+endTime[0], +endTime[1]), this.getDefaultDateFromTime(+date.getHours(), +date.getMinutes())];
    if (t1 <= time && t2 >= time) {
      this.CommonService.assessment = data;
      this.route.navigate(['home/takeExam'])

    }
    else {
      this.toastr.info("You can Start exam between start and end time");

    }



  }
  getDefaultDateFromTime(HH: number, MM: number): Date {
    const newDate = new Date(2010, 10, 20, HH, MM, 0);
    return newDate;
  }

  close() {

  }
  reject() {
    //this.route.navigateByUrl(localStorage.getItem('loginurl'))
    this.close();
  }
  accept() {
    const accp = localStorage.getItem('SA_ID');
    localStorage.setItem('SA_ID', accp + 'Accepted');
    if (localStorage.getItem('SA_ID') === this.startExamData.SA_ID + 'Accepted') {
      this.startExam(this.startExamData);
      this.openFullscreen();
    }


  }
  add(data) {
    const payload = {
      "SA_ID": data.SA_ID,
      "TNT_CODE": localStorage.getItem('TenantCode')
    }

    this.CommonService.proctoringConfig(payload).subscribe(config => {
      const cf = config;
      this.configService.setConfigData(config);

      cf.push({
        "proctoringtype_id": 6,
        "proctoring_name": "Dont minimize the window",
        "proctoring_desc": "Dont minimize the window",
        "reminder_count": 0,
        "proctoring_status": false
      },
        {
          "proctoringtype_id": 7,
          "proctoring_name": "incognito browser",
          "proctoring_desc": "Dont use incognito browser & also check that your cookies are enabled",
          "reminder_count": 0,
          "proctoring_status": false
        });
      this.termsandcondition = cf.filter(item => {
        return item.proctoring_status === false || item.reminder_count>0;

      });


      // const found = cf.filter(element => element.proctoring_status===true);
     // const result = Object.assign(cf.map(a => ({ [a.proctoring_name]: a.proctoring_status })));
      //this.configService.setConfigData(result);

    })
    this.startExamData = data;
    localStorage.setItem('SA_ID', this.startExamData.SA_ID);
    if (localStorage.getItem('SA_ID') !== this.startExamData.SA_ID + 'Accepted') {
      document.getElementById('openpopup').click();
    }

  }
  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  // async capture()  {
  //   const canvas = document.createElement("canvas");
  //   const context = canvas.getContext("2d");
  //   const video = document.createElement("video");

  //   try {
  //     const mediaDevices = navigator.mediaDevices as any;
  //      const captureStream = await mediaDevices.getDisplayMedia();
  //      console.log('capture stream.. ',captureStream);
  //     video.srcObject = captureStream;
  //     context.drawImage(video, 0, 0,);
  //     const frame = canvas.toDataURL("image/png");
  //     console.log('frame...',frame);
  //     captureStream.getTracks().forEach(track => track.stop());
  //     window.location.href = frame;
  //   } catch (err) {
  //     console.error("Error: " + err);
  //   }
  // };
}